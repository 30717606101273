<template>
  <v-card class="deskBooking ma-0 pa-0">
    <!--显示按钮组-->
    <div class="deskBooking-container" v-show="showDeskInfo">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" md="12" class="ma-0 pa-0 text-style text">
          <div class="title x-title">
            <img
              src="@/assets/images/map/meet_calendar_desk@2x.png"
              v-show="data.type == 'desk'"
              style="width: 25px; height: 20px; margin: 10px"
            />
            <img
              src="@/assets/images/map/meet_calendar_room@2x.png"
              v-show="data.type == 'room'"
              style="width: 25px; height: 25px; margin: 7px"
            />
            <span class="title-text">
              {{ data.name }}
            </span>
            <i
              class="iconfont icon-chahao"
              tabindex="0"
              style="margin-right: 20px; cursor: pointer"
              @click="backToSearchListDefault"
              @keyup.enter="backToSearchListDefault"
            ></i>
          </div>
        </v-col>
      </v-row>
      <div class="content">
        <!-- <v-img style="height: 300px" :src="data.image"></v-img> -->
        <newImg :fileUrl="data.image" style="height: 300px;width:100%;object-fit: cover;"></newImg>
        <v-card-subtitle class="pb-0">Information:</v-card-subtitle>
        <v-card-text class="text--primary">
          <div>Campus: {{ data.campusName }}</div>
          <div>{{ data.buildingName + ", " + data.floorName }}</div>
          <div>Capacity: {{ data.capacity }}</div>
        </v-card-text>

        <v-card-subtitle class="pb-0">Amenities:</v-card-subtitle>
        <v-card-text class="text--primary">
          <div v-for="a in data.featureInstances">{{ a }}</div>
        </v-card-text>
        <!--        <hr v-if="client=='NatWest' || client==='Clorox' || client==='WarnerMedia'|| client==='CX Campus'">-->
        <!--        <v-row class="ma-0" style="cursor: pointer" @click="selectDelegate" v-if="(client=='NatWest' || client==='Clorox'|| client==='WarnerMedia'|| client==='CX Campus') &&data.type=='desk'">-->
        <!--          <v-col cols="12">Booking For:</v-col>-->
        <!--          <v-col cols="6" style="font-size: 16px;font-weight: bold">{{ bookingDeletegate.name }}</v-col>-->
        <!--          <v-col cols="6" style="font-size: 16px;font-weight: bold;text-align: right">-->
        <!--            <i class="iconfont icon-duoren" style="font-size: 25px"></i>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <hr v-if="showDelegateFunction && data.type == 'desk'" />
        <v-row
          class="ma-0"
          style="cursor: pointer"
          @click="selectDelegate"
          v-if="showDelegateFunction && data.type == 'desk'"
        >
          <v-col cols="12">Booking For:</v-col>
          <v-col cols="6" style="font-size: 16px; font-weight: bold"
            >{{ bookingDeletegate.name }}
          </v-col>
          <v-col cols="6" style="font-size: 16px; font-weight: bold; text-align: right">
            <i class="iconfont icon-duoren" style="font-size: 25px"></i>
          </v-col>
        </v-row>
        <hr />
        <v-row class="ma-0" v-show="data.type == 'desk'" style="padding: 20px">
          <v-col cols="12" class="ma-0 pa-0" v-show="!showInfo">
            <h2 style="margin-bottom: 30px">Book Now For</h2>
          </v-col>
          <v-col
            v-show="data.type == 'desk'"
            cols="12"
            class="ma-0 pa-0"
            style="display: flex; justify-content: center"
          >
            <h2 v-show="showInfo" style="margin-top: 30px">
              This desk is not currently available to book.
            </h2>
            <!--desk显示按钮组 30m 60m 90m 120m 9hrs-->
            <p v-if="deskShowBtn && data.bookingType == 'Hourly'">
              <v-btn
                v-show="!showInfo"
                v-for="item in deskBtn"
                @click="clickBooking(item.value)"
                rounded
                class="x-btn"
                style="margin-bottom: 10px"
                >{{ item.label }}
              </v-btn>
            </p>
            <!--desk显示today > end of day-->
            <p v-else>
              <v-btn
                v-show="!showInfo"
                @click="clickBooking"
                rounded
                class="x-btn"
                style="margin-bottom: 10px"
              >
                {{ deskBtnLabel }}
              </v-btn>
            </p>
          </v-col>
        </v-row>
        <v-row class="ma-0" v-show="data.type == 'room'" style="padding: 20px">
          <v-col cols="12" class="ma-0 pa-0" v-show="!showInfo">
            <h2 style="margin-bottom: 30px">Book Now For</h2>
          </v-col>
          <v-col
            v-show="data.type == 'room'"
            cols="12"
            class="ma-0 pa-0"
            style="display: flex; justify-content: center"
          >
            <h2 v-show="showInfo" style="margin-top: 30px">
              This room is not currently available to book.
            </h2>
            <div v-show="!showInfo">
              <v-btn
                v-for="item in roomBtn"
                @click="clickBooking(item.value)"
                rounded
                class="x-btn"
                style="margin-bottom: 10px"
                >{{ item.label }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <!--显示book it-->
    <div class="deskBooking-container" v-show="!showDeskInfo">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" md="12" class="ma-0 pa-0 text-style text">
          <div class="title x-title">
            <img
              src="@/assets/images/map/meet_calendar_desk@2x.png"
              v-show="data.type == 'desk'"
              style="float: left; width: 25px; margin: 11px 5px"
            />
            <img
              src="@/assets/images/map/meet_calendar_room@2x.png"
              v-show="data.type == 'room'"
              style="float: left; width: 25px; margin: 8px 5px"
            />
            Book Your Space
            <i
              class="iconfont icon-chahao"
              style="float: right; margin-right: 20px; cursor: pointer"
              @click="backToSearchListDefault"
            ></i>
          </div>
        </v-col>
      </v-row>
      <div class="content">
        <v-img style="height: 300px;width:100%;object-fit: cover;" :src="data.image"></v-img>
        <v-card-text class="text--primary bookInfo x-background">
          <h2>You are booking: {{ data.name }}</h2>
          <div style="margin-bottom: 20px">
            {{ startTimeSpecific }} -
            {{ endTimeSpecific }}
          </div>
          <div>Campus: {{ data.campusName }}</div>
          <div>{{ data.buildingName + data.floorName }}</div>
          <div>Capacity: {{ data.capacity }}</div>
          <!--          <v-row class="ma-0" style="cursor: pointer;border:1px solid #fff" @click="selectDelegate"-->
          <!--                 v-if="client=='NatWest' || client==='Clorox'|| client==='WarnerMedia'|| client==='CX Campus'">-->
          <!--            <v-col cols="12" style="color:#fff;text-align: left;font-size: 17px">Booking For:</v-col>-->
          <!--            <v-col cols="6" style="font-size: 16px;font-weight: bold">{{ bookingDeletegate.name }}</v-col>-->
          <!--            <v-col cols="6" style="font-size: 16px;font-weight: bold;text-align: right">-->
          <!--              <i class="iconfont icon-duoren" style="font-size: 25px"></i>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <v-row
            class="ma-0"
            style="cursor: pointer; border: 1px solid #fff"
            @click="selectDelegate"
            v-if="showDelegateFunction && data.type == 'desk'"
          >
            <v-col cols="12" style="color: #fff; text-align: left; font-size: 17px"
              >Booking For:
            </v-col>
            <v-col cols="6" style="font-size: 16px; font-weight: bold"
              >{{ bookingDeletegate.name }}
            </v-col>
            <v-col cols="6" style="font-size: 16px; font-weight: bold; text-align: right">
              <i class="iconfont icon-duoren" style="font-size: 25px"></i>
            </v-col>
          </v-row>
          <v-col cols="12" class="ma-0 pa-0" style="display: flex; justify-content: center">
            <v-btn @click="bookIt" rounded class="x-btn" style="margin: 20px;font-weight: bold;">Book it</v-btn>
          </v-col>
        </v-card-text>
        <!--meeting信息（room才有）-->
        <div v-show="data.type == 'room'" class="meetingInfo">
          <v-text-field
            v-model="summary"
            aria-label="summary"
            label="Enter Summary"
            class="meetingInfo-input"
            single-line
            counter
            maxlength="30"
          ></v-text-field>
          <!--<v-text-field-->
          <!--v-model="description"-->
          <!--class="meetingInfo-input"-->
          <!--label="Enter Description"-->

          <!--&gt;</v-text-field>-->
          <v-textarea
            v-show="client != 'NatWest'"
            clearable
            clear-icon="cancel"
            name="input-7-1"
            class="meetingInfo-input"
            label="Enter Description"
            rows="1"
            auto-grow
            v-model="description"
          ></v-textarea>
          <v-btn
            v-for="(item, index) in onlineMeeting"
            :key="'onlineMeeting' + index"
            v-show="client != 'NatWest'"
            rounded
            outlined
            :class="{ 'x-btn-active': item.checked }"
            @click="clickAddGoogle(item, index)"
          >
            {{ item.label }}
          </v-btn>
          <v-btn rounded outlined @click="jumpToSelectPa" v-show="client != 'NatWest'">
            Select Participants
          </v-btn>
          <!--展示与会人员-->
          <staff
            v-for="(item, index) in participants"
            :key="'staff' + index"
            :ref="'staff' + index"
            :data="item"
            style="cursor: pointer"
            class="staff"
            :showCancel="true"
            v-on:cancel="cancel"
          ></staff>
        </div>
      </div>
    </div>
    <!--选择delegate（选择帮谁订）-->
    <v-dialog v-model="dialogDelegates" max-width="800">
      <selectPa
        ref="selectPa"
        v-on:backToBooking="backToBooking"
        v-on:addPa="addPa"
        :pa="participants"
        :hasborder="false"
        :isDelegate="true"
        :selectType="'booking'"
      ></selectPa>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";
import staff from "@/views/dashboard/componentsHa/staff";
import selectPa from "@/views/dashboard/componentsHa/selectPa";
import showDelegate from "@/util/delegate";
import showSelector from "@/util/selector";
import newImg from "@/views/dashboard/componentsHa/img";

export default {
  name: "deskBooking",
  props: {
    data: {
      type: Object,
    },
    time: {
      type: Object,
    },
    showDeskInfo: {
      type: Boolean,
      default: false,
    },
    // meeting的与会人员
    participants: {
      type: Array,
    },
    //是否使用了custom timeframe筛选时间
    isCustomTimeFrame: {
      type: Boolean,
      default: false,
    },
    deskShowBtn: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    staff,
    selectPa,
    newImg,
  },
  watch: {
    data(newVal, oldVal) {
      console.log("booking-data-newVal:", newVal);
      this.initBookingFor();
      this.initAddLogic();
      let minutes;

      if (newVal.type == "room") {
        console.log("pppppppppppppppppppp");
        if (!newVal.busy_time.length > 0) {
          // minutes = 120;
          if (this.client == "Edwards Life Sciences") {
            minutes = 540; //Edwards 可以预订9hr内
          } else {
            minutes = 120; //普通是可以预订2hr内
          }
        } else {
          minutes = moment(newVal.busy_time[0].start, "X").diff(
            moment(this.time.start, "X"),
            "minutes"
          );
        }
        console.log("minutes:", minutes);
        if (minutes < 30) {
          console.log("走111");
          this.roomBtn = [];
          this.showDeskInfo = true;
          this.showInfo = true;
        } else if (minutes >= 30 && minutes < 60) {
          this.roomBtn = [{ label: "30m", value: 30 }];
          this.showInfo = false;
        } else if (minutes >= 60 && minutes < 90) {
          this.roomBtn = [
            { label: "30m", value: 30 },
            { label: "60m", value: 60 },
          ];
          this.showInfo = false;
        } else if (minutes >= 90 && minutes < 120) {
          this.roomBtn = [
            { label: "30m", value: 30 },
            { label: "60m", value: 60 },
            { label: "90m", value: 90 },
          ];
          this.showInfo = false;
        } else if (minutes >= 120 && minutes < 540) {
          this.roomBtn = [
            { label: "30m", value: 30 },
            { label: "60m", value: 60 },
            { label: "90m", value: 90 },
            { label: "120m", value: 120 },
          ];
          this.showInfo = false;
        } else if (minutes >= 540) {
          this.roomBtn = [
            { label: "30m", value: 30 },
            { label: "60m", value: 60 },
            { label: "90m", value: 90 },
            { label: "120m", value: 120 },
            { label: "All Day", value: 540 },
          ];
          if (this.client == "Edwards Life Sciences") {
            this.roomBtn.reverse();
          }
          this.showInfo = false;
        } else {
          return;
        }
      } else {
        if (newVal.busy) {
          this.showInfo = true;
        } else {
          if (this.deskShowBtn && newVal.bookingType == "Hourly") {
            let minutes;
            if (!newVal.busy) {
              // minutes = 120;
              if (this.client == "Edwards Life Sciences") {
                minutes = 540; //Edwards 可以预订9hr内
              } else {
                minutes = 120; //普通是可以预订2hr内
              }
            } else {
              minutes = moment(newVal.busy_time[0].start, "X").diff(
                moment(this.time.start, "X"),
                "minutes"
              );
            }
            console.log("minutes222222222:", minutes);
            if (minutes < 30) {
              this.deskBtn = [];
              this.showInfo = true;
            } else if (minutes >= 30 && minutes < 60) {
              this.deskBtn = [{ label: "30m", value: 30 }];
              this.showInfo = false;
            } else if (minutes >= 60 && minutes < 90) {
              this.deskBtn = [
                { label: "30m", value: 30 },
                { label: "60m", value: 60 },
              ];
              this.showInfo = false;
            } else if (minutes >= 90 && minutes < 120) {
              this.deskBtn = [
                { label: "30m", value: 30 },
                { label: "60m", value: 60 },
                { label: "90m", value: 90 },
              ];
              this.showInfo = false;
            } else if (minutes >= 120 && minutes < 540) {
              this.deskBtn = [
                { label: "30m", value: 30 },
                { label: "60m", value: 60 },
                { label: "90m", value: 90 },
                { label: "120m", value: 120 },
              ];
              this.showInfo = false;
            } else if (minutes >= 540) {
              this.deskBtn = [
                { label: "30m", value: 30 },
                { label: "60m", value: 60 },
                { label: "90m", value: 90 },
                { label: "120m", value: 120 },
                { label: "All Day", value: 540 },
              ];
              this.showInfo = false;
            } else {
              return;
            }
            if (this.client == "Edwards Life Sciences") {
              let removeThirty = this.deskBtn.filter((item) => {
                return item.value > 30;
              });
              this.deskBtn = removeThirty;
              this.deskBtn.reverse();
            }
          } else {
            this.showInfo = false;
          }
        }
      }
      console.log("showInfo-data:", this.showInfo);
    },
    time(newVal, oldVal) {
      console.log("booking-time-newVal:", newVal);
      console.log("newVal.start", newVal.start);
      let timezone = localStorage.getItem("timezone");
      let currentDate;
      let bookingDate;
      let d = new Date();
      d.setSeconds(0);
      if (timezone) {
        this.startTimeSpecific = moment(newVal.start, "X").tz(timezone).format("MMM D, h:mma");
        this.endTimeSpecific = moment(newVal.end, "X").tz(timezone).format("MMM D, h:mma");
        currentDate = moment(d).tz(timezone).format("YYYY-MMM-D");
        bookingDate = moment.tz(newVal.start, "X", timezone).format("YYYY-MMM-D");
        // bookingDate = moment(newVal.start, "X").tz(timezone).format("YYYY-MMM-D")
      } else {
        this.startTimeSpecific = moment(newVal.start, "X").format("MMM D, h:mma");
        this.endTimeSpecific = moment(newVal.end, "X").format("MMM D, h:mma");
        currentDate = moment(d).format("YYYY-MMM-D");
        bookingDate = moment(newVal.start, "X").format("YYYY-MMM-D");
      }
      console.log("currentDate123:", currentDate);
      console.log("bookingDate:", bookingDate);

      // this.startTimeSpecific = moment(newVal.start, "X").format("MMM D, h:mma");
      // this.endTimeSpecific = moment(newVal.end, "X").format("MMM D, h:mma");
      // let currentDate = moment(d).format("YYYY-MMM-D");
      // console.log("currentDate:", currentDate);
      // let bookingDate = moment(newVal.start, "X").format("YYYY-MMM-D");
      // console.log("bookingDate:", bookingDate);
      if (currentDate == bookingDate) {
        if (this.client == "WarnerMedia") {
          this.deskBtnLabel = "Today";
        } else {
          this.deskBtnLabel = "Today > End of Day";
        }
      } else {
        console.log("你到这儿了123");
        if (timezone) {
          this.deskBtnLabel = moment.tz(newVal.start, "X", timezone).format("dddd MMMM D");
        } else {
          this.deskBtnLabel = moment(newVal.start, "X").format("dddd MMMM D");
        }
        console.log("deskBtnLabel:", this.deskBtnLabel);
      }
      if (this.data.type == "room") {
        let minutes;
        if (this.data.busy_time.length <= 0) {
          this.showInfo = false;
        } else {
          //最早预定的开始时间 - 当前时间
          minutes = moment(this.data.busy_time[0].start, "X").diff(
            moment(newVal.start, "X"),
            "minutes"
          );
          console.log("minutesminutesminutes", minutes);
          //大于0证明预定的开始时间比当前时间晚，计算当前可以预定的时间
          // if (minutes > 0) {
          //   if (minutes == 0) {
          //     this.roomBtn = []
          //     this.showInfo = true;
          //   } else if (minutes == 30) {
          //     this.roomBtn = [
          //       {label: '30m', value: 30},
          //     ];
          //     this.showInfo = false;
          //   } else if (minutes == 60) {
          //     this.roomBtn = [
          //       {label: '30m', value: 30},
          //       {label: '60m', value: 60},
          //     ];
          //     this.showInfo = false;
          //   } else if (minutes == 90) {
          //     this.roomBtn = [
          //       {label: '30m', value: 30},
          //       {label: '60m', value: 60},
          //       {label: '90m', value: 90},
          //     ];
          //     this.showInfo = false;
          //   } else if (minutes == 120) {
          //     this.roomBtn = [
          //       {label: '30m', value: 30},
          //       {label: '60m', value: 60},
          //       {label: '90m', value: 90},
          //       {label: '120m', value: 120},
          //     ];
          //     this.showInfo = false;
          //   } else {
          //     return
          //   }
          // }
          if (minutes > 0) {
            if (minutes >= 0 && minutes < 30) {
              console.log("33333");
              // this.roomBtn = []
              // this.showInfo = true;
              this.roomBtn = [{ label: "30m", value: 30 }];
              this.showInfo = false;
            } else if (minutes >= 30 && minutes < 60) {
              this.roomBtn = [{ label: "30m", value: 30 }];
              this.showInfo = false;
            } else if (minutes >= 60 && minutes < 90) {
              this.roomBtn = [
                { label: "30m", value: 30 },
                { label: "60m", value: 60 },
              ];
              this.showInfo = false;
            } else if (minutes >= 90 && minutes < 120) {
              this.roomBtn = [
                { label: "30m", value: 30 },
                { label: "60m", value: 60 },
                { label: "90m", value: 90 },
              ];
              this.showInfo = false;
            } else if (minutes >= 120) {
              this.roomBtn = [
                { label: "30m", value: 30 },
                { label: "60m", value: 60 },
                { label: "90m", value: 90 },
                { label: "120m", value: 120 },
              ];
              this.showInfo = false;
            } else {
              return;
            }
          } else {
            //小于0证明预定的开始时间比当前时间早，分情况讨论
            //1.预定的开始时间和结束时间都比当前时间早，则当前可以预定未来2小时
            if (this.data.busy_time[0].end <= this.time.start) {
              this.roomBtn = [
                { label: "30m", value: 30 },
                { label: "60m", value: 60 },
                { label: "90m", value: 90 },
                { label: "120m", value: 120 },
              ];
              this.showInfo = false;
            } else {
              //2.预定的开始时间比当前时间早，但是预定的结束时间比当前时间晚，则时间重叠，不能预定
              this.showInfo = true;
            }
          }
        }
      } else {
        if (this.data.busy) {
          this.showInfo = true;
        } else {
          if (this.deskShowBtn) {
            let minutes;
            minutes = moment(this.data.busy_time[0].start, "X").diff(
              moment(newVal.start, "X"),
              "minutes"
            );
            //大于0证明预定的开始时间比当前时间晚，计算当前可以预定的时间
            if (minutes > 0) {
              if (minutes == 0) {
                this.deskBtn = [];
                this.showInfo = true;
              } else if (minutes == 30) {
                this.deskBtn = [{ label: "30m", value: 30 }];
                this.showInfo = false;
              } else if (minutes == 60) {
                this.deskBtn = [
                  { label: "30m", value: 30 },
                  { label: "60m", value: 60 },
                ];
                this.showInfo = false;
              } else if (minutes == 90) {
                this.deskBtn = [
                  { label: "30m", value: 30 },
                  { label: "60m", value: 60 },
                  { label: "90m", value: 90 },
                ];
                this.showInfo = false;
              } else if (minutes == 120) {
                this.deskBtn = [
                  { label: "30m", value: 30 },
                  { label: "60m", value: 60 },
                  { label: "90m", value: 90 },
                  { label: "120m", value: 120 },
                ];
                this.showInfo = false;
              } else if (minutes == 540) {
                this.deskBtn = [
                  { label: "30m", value: 30 },
                  { label: "60m", value: 60 },
                  { label: "90m", value: 90 },
                  { label: "120m", value: 120 },
                  { label: "9hrs", value: 540 },
                ];
                this.showInfo = false;
              } else {
                return;
              }
              // Edwards的desk不要30min
              if (this.client == "Edwards Life Sciences") {
                let removeThirty = this.deskBtn.filter((item) => {
                  return item.value > 30;
                });
                this.deskBtn = removeThirty;
              }
            } else {
              //小于0证明预定的开始时间比当前时间早，分情况讨论
              //1.预定的开始时间和结束时间都比当前时间早，则当前可以预定未来2小时(不同client时间不同，像Edwards可以预订9小时)
              if (this.data.busy_time[0].end <= this.time.start) {
                this.deskBtn = [
                  { label: "30m", value: 30 },
                  { label: "60m", value: 60 },
                  { label: "90m", value: 90 },
                  { label: "120m", value: 120 },
                  { label: "9hrs", value: 540 },
                ];
                // Edwards的desk不要30min
                if (this.client == "Edwards Life Sciences") {
                  let removeThirty = this.deskBtn.filter((item) => {
                    return item.value > 30;
                  });
                  this.deskBtn = removeThirty;
                }
                this.showInfo = false;
              } else {
                //2.预定的开始时间比当前时间早，但是预定的结束时间比当前时间晚，则时间重叠，不能预定
                this.showInfo = true;
              }
            }
          } else {
            this.showInfo = false;
          }
        }
      }
      console.log("showInfo-time:", this.showInfo);
    },
  },
  data() {
    return {
      showDelegateFunction: true,
      bookingDeletegate: {},
      dialogDelegates: false,
      onlineMeeting: [], //Add participant（Add zoom）按钮的配置
      descriptionAddText: "",
      client: "",
      addGoogle: false, //Add Microsoft Teams
      showInfo: false, //false 有按钮组 true 提示不可预订
      startTime: "",
      endTime: "",
      startTimeSpecific: "",
      endTimeSpecific: "",
      roomBtn: [
        { label: "30m", value: 30 },
        { label: "60m", value: 60 },
        { label: "90m", value: 90 },
        { label: "120m", value: 120 },
      ],
      deskBtn: [
        { label: "30m", value: 30 },
        { label: "60m", value: 60 },
        { label: "90m", value: 90 },
        { label: "120m", value: 120 },
      ],
      deskBtnLabel: "Today > End of Day",
      // deskBtnLabel: 'Today > End of Day',
      showBookingBtn: true,
      summary: "", //订meeting的概要
      description: "", //订meeting的详细信息
    };
  },
  methods: {
    ...mapMutations("bookingType", ["setBookingType"]),
    // 当没有帮别人选的时候,booking for默认显示自己
    initBookingFor() {
      let obj = {};
      obj.name = localStorage.getItem("userName");
      this.bookingDeletegate = obj;
    },
    backToBooking() {
      this.dialogDelegates = false;
    },
    addPa(data) {
      this.dialogDelegates = false;
      if (data.length > 0) {
        this.bookingDeletegate = data[0];
      } else {
        this.bookingDeletegate = {};
      }
      if (!this.bookingDeletegate.id) {
        this.$confirm({
          title: "Confirmation",
          message: "Please select a User",
          show: true,
        });
        return;
      }
      console.log("==== bookingDeletegate=====", this.bookingDeletegate);
      // this.$message.success("Delegate/s Added Successfully");
    },
    selectDelegate() {
      this.dialogDelegates = true;
      // window.gtag("event", "Space", {
      //   event_category: "Space Delegate Booking",
      //   event_label: this.data.name,
      //   send_to: localStorage.getItem("googleAnaId") || "",
      //   dimension1: localStorage.getItem("campusName") || ""
      // });
      this.$gtag.event('Space', {
        event_category: 'Space Delegate Booking',
        event_label: this.data.name,
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || "",
      });
    },
    clickAddGoogle(data, index) {
      console.log("==== data=====", data);
      let that = this;
      if (data.type == "api") {
        if (data.checked) {
          that.$set(that.onlineMeeting[index], "checked", false);
          let i = that.description.indexOf(that.descriptionAddText);
          console.log("====description =====", that.description);
          console.log("====descriptionAddText =====", that.descriptionAddText);
          console.log("====i =====", i);
          // console.log("====123 =====",that.description.indexOf(that.descriptionAddText));
          that.description = that.description.substring(0, i);
        } else {
          let title = that.summary;
          let email = localStorage.getItem("userEmail");
          let start = this.time.start;
          let duration = Number(this.time.end) - Number(this.time.start);
          let meetingId = localStorage.getItem("meetingIdMap");
          let apiType = that.onlineMeeting.name;
          this.bus.$emit("loading", true);
          // console.log("====time =====",that.time)
          // console.log("====title =====",title)
          // console.log("====email =====",email)
          // console.log("====start =====",start)
          // console.log("====end =====",end)
          // console.log("====title =====",title)
          // console.log("====duration =====",duration)
          // console.log("====meetingId =====",meetingId)
          // console.log("====apiType =====",apiType)
          this.$axios({
            method: "GET",
            url: "/m/onlinemeeting/create",
            data: {
              title: title,
              email: email,
              start: start,
              duration: duration,
              meetingId: meetingId,
              apiType: apiType,
            },
          })
            .then((res) => {
              this.bus.$emit("loading", false);
              console.log("res:", res);
              if (res.data.result == "failed") {
                that
                  .$confirm({
                    title: "Confirmation",
                    message: res.data.message,
                    show: true,
                  })
                  .then((res) => {
                    // that.addGoogle = false;
                    that.$set(that.onlineMeeting[index], "checked", false);
                  });
              } else {
                console.log("====res.data.data.text!!!!!!!!!!!!! =====", res.data.data.text);
                // that.addGoogle = true;
                that.$set(that.onlineMeeting[index], "checked", true);
                that.descriptionAddText = res.data.data.text;
                that.description += "\n";
                that.description = that.description.concat(res.data.data.text);
              }
            })
            .catch((err) => {
              this.bus.$emit("loading", false);
              console.log(err.response);
              let auth = "";
              if (err.response.data && err.response.data.auth) {
                auth = err.response.data.auth;
                auth = auth.replace("microsoft-edge-", "");
              }
              if (auth) {
                window.open(auth);
              }

              console.log(err);
            });
        }
      } else {
        this.addGoogle = !this.addGoogle;
        that.$set(that.onlineMeeting[index], "checked", !data.checked);
      }
    },
    // clickAddGoogle(data,index) {
    //   let that = this;
    //   that.$set(that.onlineMeeting[index],"checked",!data.checked);
    //   console.log("==== onlineMeeting=====",that.onlineMeeting);
    // },
    // 右侧点击叉号删除与会人员
    cancel(data) {
      this.$emit("cancelPa", data);
    },
    // cancel(data){
    //   let length = this.participants.length;
    //   for(let i = 0; i < length; i++){
    //     if(data.id==this.participants[i].id){
    //       this.participants.splice(i,1);
    //       break;
    //     }
    //   }
    // },
    // 选参加会议的人
    jumpToSelectPa() {
      this.$emit("jumpToSelectPa", this.participants);
    },
    // 预定room/desk
    // 带有开始时间和结束时间有限制的
    clickBooking(minutes) {
      let that = this;
      console.log("==== isCustomTimeFrame!!!!!!!=====", this.isCustomTimeFrame);
      console.log("==== minutes!!!!!!!=====", minutes);
      console.log("clickBooking", this.data);
      let timezone = localStorage.getItem("timezone");
      if (this.data.type == "desk") {
        let d = new Date();
        d.setSeconds(0);
        let year;
        let month;
        let date;
        if (timezone) {
          year = String(moment.tz(d, timezone).format("YYYY"));
          month = String(moment.tz(d, timezone).format("M"));
          date = String(moment.tz(d, timezone).format("D"));
        } else {
          year = String(d.getFullYear());
          month = String(d.getMonth() + 1);
          date = String(d.getDate());
        }
        // let year = String(d.getFullYear());
        // let month = String(d.getMonth() + 1);
        // let date = String(d.getDate());
        let dateString = year + "-" + month + "-" + date;
        console.log("dateString:", dateString);
        console.log(localStorage.getItem("workingHourStartTime"));
        let startTime = localStorage.getItem("workingHourStartTime") || "00:00";
        let endTime = localStorage.getItem("workingHourEndTime") || "23:59";
        console.log("startTime:", startTime);
        console.log("endTime:", endTime);
        let workingHourStartTime = localStorage.getItem("workingHourStartTime");
        let workingHourEndTime = localStorage.getItem("workingHourEndTime");
        let workingHourStartTimeAndYear = dateString + "-" + workingHourStartTime;
        let workingHourEndTimeAndYear = dateString + "-" + workingHourEndTime;
        let startTimeLocalUnix;
        let endTimeLocalUnix;
        if (timezone) {
          startTimeLocalUnix = moment
            .tz(workingHourStartTimeAndYear, "YYYY-M-D-HH:mm", timezone)
            .unix();
          endTimeLocalUnix = moment
            .tz(workingHourEndTimeAndYear, "YYYY-M-D-HH:mm", timezone)
            .unix();
          console.log("startTimeLocalUnix", startTimeLocalUnix);
        } else {
          startTimeLocalUnix = moment(startTime, "HH:mm").local().unix();
          endTimeLocalUnix = moment(endTime, "HH:mm").local().unix();
        }

        // let startTimeLocalUnix = moment(startTime, 'HH:mm').local().unix();
        // let endTimeLocalUnix = moment(endTime, 'HH:mm').local().unix();

        // ==============用来显示pop up 提示的时间============
        let startDate = "";
        let endDate = "";
        let startString = "";
        let endString = "";
        // ================================================
        let obj = {};
        let timeHasValue = true;
        // if (startTime == '' || startTime == 'undefined' || endTime == '' || endTime == 'undefined') {
        //   timeHasValue = false;
        // }
        if (
          workingHourStartTime == null ||
          workingHourStartTime == "" ||
          workingHourStartTime == "undefined" ||
          workingHourEndTime == "" ||
          workingHourEndTime == "undefined" ||
          workingHourEndTime == null
        ) {
          timeHasValue = false;
        }
        if (timeHasValue) {
          console.log("有时间范围限制");
          console.log("==== this.time.start=====", this.time.start);
          console.log("==== endTimeLocalUnix=====", endTimeLocalUnix);
          console.log("==== startTimeLocalUnix=====", startTimeLocalUnix);
          let shouldReturn = false;
          // 如果有时间范围限制
          if (this.time.start > endTimeLocalUnix) {
            this.$confirm({
              title: "Confirmation",
              message: "Cannot book this desk at this time!",
              show: false,
            });
            return;
          }
          console.log("====shouldReturn =====", shouldReturn);
          if (shouldReturn) {
            return;
          }
          // if (this.time.start >= startTimeLocalUnix) {
          //   startTimeLocalUnix = this.time.start;
          //   // startTimeLocalUnix = moment(d).unix();
          //   if (timezone) {
          //     startDate = moment
          //       .tz(startTimeLocalUnix, "X", timezone)
          //       .format("MMMM D");
          //     endDate = moment
          //       .tz(startTimeLocalUnix, "X", timezone)
          //       .format("MMMM D");
          //     startTime = moment
          //       .tz(startTimeLocalUnix, "X", timezone)
          //       .format("h:mm a");
          //     endTime = moment
          //       .tz(endTimeLocalUnix, "X", timezone)
          //       .format("h:mm a");
          //     // endTime = moment(endTimeLocalUnix, "X").format("h:mm a");
          //   } else {
          //     startDate = moment(startTimeLocalUnix, "X").format("MMMM D");
          //     endDate = moment(startTimeLocalUnix, "X").format("MMMM D");
          //     startTime = moment(startTimeLocalUnix, "X").format("h:mm a");
          //     endTime = moment(endTimeLocalUnix, "X").format("h:mm a");
          //   }
          //   // startTime = moment(startTimeLocalUnix, "X").format("HH:mm");
          //   // startDate = moment(startTimeLocalUnix, "X").format("MMMM D");
          //   // endDate = moment(startTimeLocalUnix, "X").format("MMMM D");
          //   startString = startDate + "\xa0at\xa0" + startTime;
          //   endString = endDate + "\xa0at\xa0" + endTime;
          // }
          if (this.time.start >= startTimeLocalUnix) {
            console.log("开始时间大于规定的开始时间");
            startTimeLocalUnix = this.time.start;
          }
          if (timezone) {
            startDate = moment.tz(startTimeLocalUnix, "X", timezone).format("MMMM D");
            endDate = moment.tz(startTimeLocalUnix, "X", timezone).format("MMMM D");
            startTime = moment.tz(startTimeLocalUnix, "X", timezone).format("h:mm a");
            endTime = moment.tz(endTimeLocalUnix, "X", timezone).format("h:mm a");
          } else {
            startDate = moment(startTimeLocalUnix, "X").format("MMMM D");
            endDate = moment(startTimeLocalUnix, "X").format("MMMM D");
            startTime = moment(startTimeLocalUnix, "X").format("h:mm a");
            endTime = moment(endTimeLocalUnix, "X").format("h:mm a");
          }
          startString = startDate + "\xa0at\xa0" + startTime;
          endString = endDate + "\xa0at\xa0" + endTime;
          const timeDiff = (endTimeLocalUnix - startTimeLocalUnix) / 60; //相差分钟数
          // console.log("====startTimeLocalUnix =====",startTimeLocalUnix)
          // console.log("====endTimeLocalUnix =====",endTimeLocalUnix)
          // console.log("====timeDiff =====",timeDiff)
          console.log("startDate:", startDate);
          console.log("endDate:", endDate);
          console.log("startTime:", startTime);
          console.log("endTime:", endTime);
          console.log("startString:", startString);
          console.log("endString:", endString);
          let hour = parseInt(timeDiff / 60);
          let min = timeDiff % 60;
          obj.start = startTimeLocalUnix;
          obj.end = endTimeLocalUnix;
          let msg = `You are booking this desk from ${startString} to ${endString}`;
          console.log("startTimeLocalUnix:", startTimeLocalUnix);
          console.log("endTimeLocalUnix:", endTimeLocalUnix);

          // let msg = `You are booking this desk from ${startString} to ${endString} (${hour} hours and ${min} minutes)`
          this.$confirm({
            title: "Confirmation",
            message: msg,
            show: true,
            showTwoBtn: true,
            okMsg: "Confirm",
            cancelMsg: "Cancel",
          })
            .then((res) => {
              this.bookIt(startTimeLocalUnix, endTimeLocalUnix); //直接订desk了，不跳到Book it的页面了
            })
            .catch((err) => {
              return;
            });
        } else {
          console.log("====没有时间范围限制 =====");
          // 没有时间范围限制
          if (this.deskShowBtn && that.data.bookingType == "Hourly") {
            console.log("====展示按钮组的情况 =====");
            // desk展示按钮组30m 60m 90m 120m 540m的情况
            let that = this;
            let msg = "";
            let d = new Date();
            d.setSeconds(0);
            let start;
            let startDisplay;
            let remainder;
            let timezone = localStorage.getItem("timezone");
            if (timezone) {
              start = moment(d).tz(timezone);
              remainder = start.minute() % 30; //距离前面整（半）点的分钟
              startDisplay = moment(d).tz(timezone).add(-remainder, "minutes");
            } else {
              start = moment(d);
              remainder = start.minute() % 30; //距离前面整（半）点的分钟
              startDisplay = moment(d).add(-remainder, "minutes");
            }
            // const start = moment(d);
            let startTimeUnix = this.time.start;
            // let remainder = start.minute() % 30;//距离前面整（半）点的分钟
            // const startDisplay = moment(d).add(-remainder, "minutes");
            const endDisplay = moment(startDisplay)
              .add(+minutes, "minutes")
              .set({ second: 0 })
              .add(-1, "seconds");
            console.log("endDisplay", endDisplay);
            console.log("endDisplayUnix", moment(endDisplay).unix());
            // const endDisplay = moment(startDisplay).add(+(minutes), 'minutes');
            // const remainder2 = 30 - (start.minute() % 30);//距离后面整（半）点的分钟
            const timeDiff = endDisplay.diff(start, "minutes"); //距离后面整（半）点的分钟
            console.log("==== timeDiff=====", timeDiff);
            // let msg = "";
            let startDate = start.format("MMMM D");
            let startTime = start.format("h:mm a");
            let startString = startDate + "\xa0at\xa0" + startTime;
            console.log("startString1111111111111111111", startString);
            console.log("startString22222", moment(start).tz(timezone).unix());
            let endDate = endDisplay.format("MMMM D");
            let endTime = endDisplay.format("h:mm a");
            console.log(" this.time", this.time);
            console.log("startTimeUnix", startTimeUnix);
            console.log("endTime", endTime);
            let endString = endDate + "\xa0at\xa0" + endTime;
            msg = `You are booking this desk from ${startString} to ${endString} (${timeDiff}minutes)`;

            console.log("==== this.time.start!!!!=====", this.time.start);
            this.$confirm({
              title: "Confirmation",
              message: msg,
              show: true,
              showTwoBtn: true,
              okMsg: "Confirm",
              cancelMsg: "Cancel",
            })
              .then(() => {
                //用户点击确认后执行
                let obj = {};
                // obj.start = that.time.start;//去整（半）点的时间 例如：3：35 显示 3：00 （旧版做法）
                obj.start = start.unix(); //显示准确的时间 例如：3：35 显示 3：35  （新改动）
                obj.end = moment(startTimeUnix, "X")
                  .add(+minutes, "minutes")
                  .add(-remainder, "minutes")
                  .set({ second: 0 })
                  .add(-1, "seconds")
                  .unix();
                console.log("==== that.time.start!!!!=====", that.time.start);
                console.log("==== obj!!!!=====", obj);
                that.$emit("changeBookTime", obj);
                that.$emit("changeDeskInfoToFalse", true);
              })
              .catch(() => {
                // 取消或关闭
              });
          } else {
            console.log("==== 展示today > end of day的情况=====");
            // 展示today > end of day的情况
            // obj.start = this.time.start;
            // obj.end = moment(this.time.start, "X").endOf('day').unix();
            let d = new Date();
            d.setSeconds(0);
            let timezone = localStorage.getItem("timezone");
            if (timezone) {
              console.log("zou111");
              obj.start = moment.tz(d, timezone).unix();
              obj.end = moment.tz(this.time.start, "X", timezone).endOf("day").unix();
            } else {
              console.log("zou222");
              obj.start = moment(d).unix();
              obj.end = moment(this.time.start, "X").endOf("day").unix();
            }
            console.log("obj.start", obj.start);
            console.log("obj.end", obj.end);
            this.bookIt(obj.start, obj.end); //直接订desk了，不跳到Book it的页面了
            // this.bookIt();//直接订desk了，不跳到Book it的页面了
          }
        }
        // this.$emit("changeBookTime", obj);
      } else {
        console.log("====time!!!!!! =====", this.time);
        console.log("==== minutes=====", minutes);
        let that = this;
        let msg = "";
        if (this.isCustomTimeFrame) {
          console.log("clickBooking-isCustomTimeFrame:", this.isCustomTimeFrame);
          let startDate;
          let startTime;
          let endDate;
          let endTime;
          let timezone = localStorage.getItem("timezone");
          if (timezone) {
            startDate = moment(this.time.start, "X").tz(timezone).format("MMMM D");
            startTime = moment(this.time.start, "X").tz(timezone).format("h:mm a");
            endDate = moment(this.time.end, "X").tz(timezone).format("MMMM D");
            endTime = moment(this.time.start, "X")
              .add("minutes", minutes)
              .tz(timezone)
              .format("h:mm a");
            console.log("startDate:", startDate);
            console.log("startTime:", startTime);
            console.log("endDate:", endDate);
            console.log("endTime:", endTime);
          } else {
            startDate = moment(this.time.start, "X").format("MMMM D");
            startTime = moment(this.time.start, "X").format("h:mm a");
            endDate = moment(this.time.end, "X").format("MMMM D");
            endTime = moment(this.time.start, "X").add("minutes", minutes).format("h:mm a");
          }

          // let startDate = moment(this.time.start, "X").format("MMMM D");
          // let startTime = moment(this.time.start, "X").format("h:mm a");
          let startString = startDate + "\xa0at\xa0" + startTime;
          // let endDate = moment(this.time.end, "X").format("MMMM D");
          // let endTime = moment(this.time.start, "X").add("minutes", minutes).format("h:mm a");
          let endString = endDate + "\xa0at\xa0" + endTime;
          msg = `You are booking this room from ${startString} to ${endString} (${minutes}minutes)`;
          this.$confirm({
            title: "Confirmation",
            message: msg,
            show: true,
            showTwoBtn: true,
            okMsg: "Confirm",
            cancelMsg: "Cancel",
          })
            .then(() => {
              console.log("123");
              //用户点击确认后执行
              let obj = {};
              obj.start = that.time.start;
              // console.log(' obj.start:', obj.start);
              // let remainder = obj.start.minute() % 30;//距离前面整（半）点的分钟
              // console.log('remainder~~~~~~~~~~~~~~~~~~~~~~~~~~~',remainder);
              // obj.end = moment(that.time.start, "X").add(+(minutes), 'minutes').add(-remainder, "minutes").unix();
              obj.end = moment(that.time.start, "X").add(+minutes, "minutes").unix();
              that.$emit("changeBookTime", obj);
              that.$emit("changeDeskInfoToFalse", true);
            })
            .catch(() => {
              // 取消或关闭
            });
        } else {
          console.log("clickBooking-isCustomTimeFrame:", this.isCustomTimeFrame);
          let d = new Date();
          d.setSeconds(0);
          let start;
          let startDisplay;
          let timezone = localStorage.getItem("timezone");
          if (timezone) {
            start = moment(d).tz(timezone);
            console.log("start1111111111111111", start);
            let remainder = start.minute() % 30; //距离前面整（半）点的分钟
            startDisplay = moment(d).tz(timezone).add(-remainder, "minutes");
          } else {
            start = moment(d);
            console.log("start222222222222222", start);
            let remainder = start.minute() % 30; //距离前面整（半）点的分钟
            startDisplay = moment(d).add(-remainder, "minutes");
          }
          // const start = moment(d);
          // let remainder = start.minute() % 30;//距离前面整（半）点的分钟
          // const startDisplay = moment(d).add(-remainder, "minutes");
          const endDisplay = moment(startDisplay).add(+minutes, "minutes");
          // const remainder2 = 30 - (start.minute() % 30);//距离后面整（半）点的分钟
          const timeDiff = endDisplay.diff(start, "minutes"); //距离后面整（半）点的分钟
          console.log("==== timeDiff=====", timeDiff);
          // let msg = "";
          let startDate = start.format("MMMM D");
          let startTime = start.format("h:mm a");
          let startString = startDate + "\xa0at\xa0" + startTime;
          let endDate = endDisplay.format("MMMM D");
          let endTime = endDisplay.format("h:mm a");
          let endString = endDate + "\xa0at\xa0" + endTime;
          msg = `You are booking this room from ${startString} to ${endString} (${timeDiff}minutes)`;
          this.$confirm({
            title: "Confirmation",
            message: msg,
            show: true,
            showTwoBtn: true,
            okMsg: "Confirm",
            cancelMsg: "Cancel",
          })
            .then(() => {
              console.log("456");
              //用户点击确认后执行
              let obj = {};
              let timezone = localStorage.getItem("timezone");
              // obj.start = that.time.start;//去整（半）点的时间 例如：3：35 显示 3：00 （旧版做法）
              let startFormatString = moment(start).format("YYYY-MM-DD HH:mm:ss");
              console.log("startFormatString", startFormatString);
              // obj.start = moment(startFormatString).unix(); //直接用start.unix() 时间戳还是本地的时间，不知道为什么有这个bug，所以换成这种写法
              obj.start = start.unix(); //显示准确的时间 例如：3：35 显示 3：35  （新改动）
              let remainder = start.minute() % 30; //距离前面整（半）点的分钟
              console.log("remainder~~~~~~~~~~~~~~~~~~~~~~~~~~~", remainder);
              obj.end = moment(obj.start, "X")
                .add(+minutes, "minutes")
                .add(-remainder, "minutes")
                .unix();
              // obj.end = moment(obj.start, "X").add(-remainder, "minutes").unix();
              console.log("obj!!!!!!!", obj);
              that.$emit("changeBookTime", obj);
              that.$emit("changeDeskInfoToFalse", true);
            })
            .catch(() => {
              // 取消或关闭
            });
        }

        // console.log("====startString =====",startString)
        // console.log("====endString =====",endString)
        // this.$confirm({
        //   title: "Confirmation",
        //   message: msg,
        //   show: true,
        //   showTwoBtn: true,
        //   okMsg: 'Confirm',
        //   cancelMsg: 'Cancel',
        // })
        //   .then(() => {
        //     //用户点击确认后执行
        //     let obj = {};
        //     // obj.start = that.time.start;//去整（半）点的时间 例如：3：35 显示 3：00 （旧版做法）
        //     obj.start = start.unix();//显示准确的时间 例如：3：35 显示 3：35  （新改动）
        //     obj.end = moment(that.time.start, "X").add(+(minutes), 'minutes').unix();
        //     that.$emit("changeBookTime", obj);
        //     that.$emit("changeDeskInfoToFalse", true);
        //   })
        //   .catch(() => {
        //     // 取消或关闭
        //
        //   });
      }
    },

    // clickBooking(minutes) {
    //   if (this.data.type == 'desk') {
    //     let obj = {};
    //     obj.start = this.time.start;
    //     obj.end = moment(this.time.start, "X").endOf('day').unix();
    //     console.log("obj:", obj);
    //     this.$emit("changeBookTime", obj);
    //     // this.$emit("changeDeskInfoToFalse", true);//显示Book it
    //     this.bookIt();//直接订desk了，不跳到Book it的页面了
    //   } else {
    //     console.log("==== minutes=====", minutes)
    //     let that = this;
    //     let d = new Date();
    //     const start = moment(d);
    //     let remainder = start.minute() % 30;//距离前面整（半）点的分钟
    //     const startDisplay = moment(d).add(-remainder, "minutes");
    //     const endDisplay = moment(startDisplay).add(+(minutes), 'minutes');
    //     // const remainder2 = 30 - (start.minute() % 30);//距离后面整（半）点的分钟
    //     const timeDiff = endDisplay.diff(start, 'minutes');//距离后面整（半）点的分钟
    //     console.log("==== timeDiff=====", timeDiff)
    //     let msg = "";
    //     let startDate = start.format("MMMM D");
    //     let startTime = start.format("h:mm a");
    //     let startString = startDate + '\xa0at\xa0' + startTime;
    //     let endDate = endDisplay.format("MMMM D");
    //     let endTime = endDisplay.format("h:mm a");
    //     let endString = endDate + '\xa0at\xa0' + endTime;
    //     // console.log("====startString =====",startString)
    //     // console.log("====endString =====",endString)
    //     msg = `You are booking this room from ${startString} to ${endString} (${timeDiff}minutes)`
    //     this.$confirm({
    //       title: "Confirmation",
    //       message: msg,
    //       show: true,
    //       showTwoBtn: true,
    //       okMsg: 'Confirm',
    //       cancelMsg: 'Cancel',
    //     })
    //       .then(() => {
    //         //用户点击确认后执行
    //         let obj = {};
    //         // obj.start = that.time.start;//去整（半）点的时间 例如：3：35 显示 3：00 （旧版做法）
    //         obj.start = start.unix();//显示准确的时间 例如：3：35 显示 3：35  （新改动）
    //         obj.end = moment(that.time.start, "X").add(+(minutes), 'minutes').unix();
    //         that.$emit("changeBookTime", obj);
    //         that.$emit("changeDeskInfoToFalse", true);
    //       })
    //       .catch(() => {
    //         // 取消或关闭
    //
    //       });
    //   }
    // },

    // book Desk的开始时间和结束时间是有限制的
    // bookIt: function (startTime, endTime) {
    //   let timezone = localStorage.getItem("timezone");
    //   let dateString;
    //   if (timezone) {
    //     dateString = moment.tz(this.time.start, "X", timezone).format("YYYY-M-D");
    //   } else {
    //     dateString = moment(this.time.end, "X").format("YYYY-M-D");
    //   }
    //   let workingHourStartTime = localStorage.getItem("workingHourStartTime")
    //     ? localStorage.getItem("workingHourStartTime")
    //     : "00:00";
    //   let workingHourEndTime = localStorage.getItem("workingHourEndTime")
    //     ? localStorage.getItem("workingHourEndTime")
    //     : "23:59";
    //   let workingHourStartTimeAndYear = dateString + "-" + workingHourStartTime;
    //   let workingHourEndTimeAndYear = dateString + "-" + workingHourEndTime;
    //   let startTimeLocalUnix;
    //   let endTimeLocalUnix;
    //   if (timezone) {
    //     startTimeLocalUnix = moment
    //       .tz(workingHourStartTimeAndYear, "YYYY-M-D-HH:mm", timezone)
    //       .unix();
    //     endTimeLocalUnix = moment.tz(workingHourEndTimeAndYear, "YYYY-M-D-HH:mm", timezone).unix();
    //   } else {
    //     startTimeLocalUnix = moment(workingHourStartTimeAndYear, "YYYY-M-D-HH:mm").unix();
    //     endTimeLocalUnix = moment(workingHourEndTimeAndYear, "YYYY-M-D-HH:mm").unix();
    //   }
    //   let realStart;
    //   let realEnd;
    //   if (this.data.type == "room") {
    //     this.bus.$emit("loading", true);
    //     if (this.time.start < startTimeLocalUnix) {
    //       realStart = startTimeLocalUnix;
    //     } else {
    //       realStart = this.time.start;
    //     }
    //     if (this.time.end > endTimeLocalUnix) {
    //       realEnd = endTimeLocalUnix;
    //     } else {
    //       realEnd = this.time.end;
    //     }

    //     let room = [];
    //     room.push(this.data.resourceId);
    //     let attendees = [];
    //     this.participants.map((item) => {
    //       attendees.push(item.email);
    //     });
    //     console.log("====this.bookingDeletegate =====", this.bookingDeletegate);
    //     // let isEmptyObj = Object.keys(this.bookingDeletegate).length == 0;//之前是判断booking for是否为空，空的话就calendarId就传空，不为空的话calendarId传当前userEmail，但逻辑改了一下，UI上Booking For默认展示自己，所以this.bookingDeletegate默认是自己，只是属性中只有name没有id，故改成下面判断有无id的逻辑来实现

    //     let calendarId;
    //     // 如果bookingDelegate选了,这个时候calendarId传空
    //     if (this.bookingDeletegate.id) {
    //       calendarId = "";
    //     } else {
    //       // 如果bookingDelegate没选,则这个时候calendarId传当前登录用户的userEmail
    //       calendarId = localStorage.userEmail;
    //     }
    //     // let calendarId = isEmptyObj ? localStorage.userEmail : "";
    //     this.$axios({
    //       method: "POST",
    //       url: "/m/spaces/insertEvent/",
    //       data: {
    //         isOnlineMeeting: this.addGoogle ? 1 : 0, //是否显示email phone之类得详细信息
    //         calendarId: calendarId, //订房间的人的email
    //         rooms: room, //房间的resourceId, 数组
    //         meetingId: localStorage.meetingIdMap, //campus的id
    //         // start: this.time.start, //开始时间戳
    //         // end: this.time.end, //结束时间戳
    //         start: realStart, //开始时间戳
    //         end: realEnd, //结束时间戳
    //         summary: this.summary, //标题
    //         description: this.description, //详情
    //         attendees: attendees, //参会人员的email, 数组
    //         delegateFor: this.bookingDeletegate.id,
    //       },
    //     })
    //       .then((res) => {
    //         this.bus.$emit("loading", false);
    //         if (res.data.result == "failed") {
    //           this.$confirm({
    //             title: "Confirmation",
    //             message: res.data.message,
    //             show: true,
    //           });
    //           return;
    //         }
    //         this.$message.success("Success");
    //         var arr = Object.keys(this.bookingDeletegate);
    //         console.log("arr!!!!!!!!!!!!!!!", arr);
    //         console.log("bookingDeletegate!!!!!!!!!!!!!!!", this.bookingDeletegate);
    //         if (arr.length == 0) {
    //           this.setBookingType("Meetings");
    //         } else {
    //           if (this.bookingDeletegate.name == localStorage.userName) {
    //             this.setBookingType("Meetings");
    //           } else {
    //             this.setBookingType("Others");
    //           }
    //         }
    //         this.$router.push({
    //           name: "booking",
    //           params: { start: this.time.start, end: this.time.end },
    //         });
    //         // if (window.gtag) {
    //         //   window.gtag("event", "Space", {
    //         //     event_category: "Space Book Succeed",
    //         //     event_label: this.data.name,
    //         //     send_to: localStorage.getItem("googleAnaId") || "",
    //         //     dimension1: localStorage.getItem("campusName") || ""
    //         //   });
    //         // }
    //         this.$gtag.event('Space', {
    //           event_category: 'Space Book Succeed',
    //           event_label: this.data.name,
    //           send_to: localStorage.getItem("googleAnaId") || "",
    //           dimension1: localStorage.getItem("campusName") || "",
    //         });
    //       })
    //       .catch((err) => {
    //         this.bus.$emit("loading", false);
    //         console.log(err);
    //       });
    //   } else if (this.data.type == "desk") {
    //     console.log("Desk----time", this.time);
    //     console.log("startTime", startTime);
    //     console.log("endTime", endTime);

    //     if (
    //       this.time.start < startTimeLocalUnix ||
    //       (!isNaN(startTime) && startTime < startTimeLocalUnix)
    //     ) {
    //       realStart = startTimeLocalUnix;
    //     } else {
    //       realStart = isNaN(startTime) ? this.time.start : startTime;
    //     }
    //     if (this.time.end > endTimeLocalUnix || (!isNaN(endTime) && endTime > endTimeLocalUnix)) {
    //       console.log('zou1');
    //       realEnd = this.time.end;
    //     } else {
    //       console.log('zou2');
    //       realEnd = isNaN(endTime) ? this.time.end : endTime;
    //     }

    //     let deskEndTimeZero = moment(this.time.end, "X").set({ second: 0 });
    //     let deskEndTimeZeroMinerOneSecond = moment(deskEndTimeZero, "X").add(-1, "seconds").unix();
    //     // console.log('deskEndTimeZeroMinerOneSecond',deskEndTimeZeroMinerOneSecond);
    //     if (this.bookingDeletegate.id) {
    //       // if (window.gtag) {
    //       //   window.gtag("event", "Book For", {
    //       //     event_category: "Book a desk for someone",
    //       //     event_label: this.bookingDeletegate.name,
    //       //     send_to: localStorage.getItem("googleAnaId") || "",
    //       //     dimension1: localStorage.getItem("campusName") || ""
    //       //   });
    //       // }
    //       this.$gtag.event('Book For', {
    //         event_category: 'Book a desk for someone',
    //         event_label: this.bookingDeletegate.name,
    //         send_to: localStorage.getItem("googleAnaId") || "",
    //         dimension1: localStorage.getItem("campusName") || "",
    //       });
    //     }
    //     this.bus.$emit("loading", true);
    //     this.$axios({
    //       // method: 'get',
    //       // url: '/m/desk/reserveSeat',
    //       method: this.$usev2 ? "post" : "get",
    //       url: this.$usev2 ? "/m/v2/desk/reserveSeat" : "/m/desk/reserveSeat",
    //       data: {
    //         meetingId: localStorage.meetingIdMap,
    //         seatId: this.data.resourceId,
    //         // start: isNaN(startTime) ? this.time.start : startTime,
    //         // end: isNaN(endTime) ? this.time.end : endTime,
    //         start: realStart,
    //         end: realEnd,
    //         // end: isNaN(endTime) ? deskEndTimeZeroMinerOneSecond : endTime,
    //         delegateFor: this.bookingDeletegate.id ? this.bookingDeletegate.id : "",
    //       },
    //     })
    //       .then((res) => {
    //         this.bus.$emit("loading", false);
    //         if (res.data.result == "failed") {
    //           this.$confirm({
    //             title: "Confirmation",
    //             message: res.data.message,
    //             show: true,
    //           });
    //           return;
    //         }
    //         this.$message.success("Success");
    //         if (this.showDelegateFunction) {
    //           console.log("111111111111111");
    //           var arr = Object.keys(this.bookingDeletegate);
    //           console.log("arr11", arr);
    //           if (arr.length == 0) {
    //             this.setBookingType("Desks / Offices");
    //           } else {
    //             if (this.bookingDeletegate.id) {
    //               this.setBookingType("Others");
    //             } else {
    //               this.setBookingType("Desks / Offices");
    //             }
    //             // this.setBookingType('Others');
    //           }
    //         } else {
    //           console.log("222222222222222");
    //           var arr = Object.keys(this.bookingDeletegate);
    //           if (arr.length == 0) {
    //             this.setBookingType("Desks");
    //           } else {
    //             if (this.bookingDeletegate.id) {
    //               this.setBookingType("Others");
    //             } else {
    //               this.setBookingType("Desks");
    //             }
    //           }
    //         }
    //         this.$router.push({
    //           name: "booking",
    //           params: { start: this.time.start, end: this.time.end },
    //         });
    //         // if (window.gtag) {
    //           // window.gtag("event", "Space ", {
    //           //   event_category: "Space Book Succeed",
    //           //   event_label: this.data.name,
    //           //   send_to: localStorage.getItem("googleAnaId") || "",
    //           //   dimension1: localStorage.getItem("campusName") || ""
    //           // });
    //           this.$gtag.event('Space', {
    //             event_category: 'Space Book Succeed',
    //             event_label: this.data.name,
    //             send_to: localStorage.getItem("googleAnaId") || "",
    //             dimension1: localStorage.getItem("campusName") || "",
    //           });
    //           if (this.bookingDeletegate.id) {
    //             // window.gtag("event", "Space ", {
    //             //   event_category: "Space Delegate Booking",
    //             //   event_label: this.data.name,
    //             //   send_to: localStorage.getItem("googleAnaId") || "",
    //             //   dimension1: localStorage.getItem("campusName") || ""
    //             // });
    //             this.$gtag.event('Space', {
    //               event_category: 'Space Delegate Booking',
    //               event_label: this.data.name,
    //               send_to: localStorage.getItem("googleAnaId") || "",
    //               dimension1: localStorage.getItem("campusName") || "",
    //             });
    //           }
    //         // }
    //       })
    //       .catch((err) => {
    //         this.bus.$emit("loading", false);
    //         console.log(err);
    //       });
    //   }
    // },
    bookIt: function (startTime, endTime) {
      let timezone = localStorage.getItem("timezone");
      let dateString;
      let dateStringEnd;
      if (timezone) {
        dateString = moment.tz(this.time.start, "X", timezone).format("YYYY-M-D");
        dateStringEnd = moment.tz(this.time.end, "X", timezone).format("YYYY-M-D");
      } else {
        dateString = moment(this.time.start, "X").format("YYYY-M-D");
        dateStringEnd = moment(this.time.end, "X").format("YYYY-M-D");
      }
      let workingHourStartTime = localStorage.getItem("workingHourStartTime")
        ? localStorage.getItem("workingHourStartTime")
        : "00:00";
      let workingHourEndTime = localStorage.getItem("workingHourEndTime")
        ? localStorage.getItem("workingHourEndTime")
        : "23:59";
      let workingHourStartTimeAndYear = dateString + "-" + workingHourStartTime;
      let workingHourEndTimeAndYear = dateStringEnd + "-" + workingHourEndTime;
      let startTimeLocalUnix;
      let endTimeLocalUnix;
      if (timezone) {
        startTimeLocalUnix = moment
          .tz(workingHourStartTimeAndYear, "YYYY-M-D-HH:mm", timezone)
          .unix();
        endTimeLocalUnix = moment.tz(workingHourEndTimeAndYear, "YYYY-M-D-HH:mm", timezone).unix();
      } else {
        startTimeLocalUnix = moment(workingHourStartTimeAndYear, "YYYY-M-D-HH:mm").unix();
        endTimeLocalUnix = moment(workingHourEndTimeAndYear, "YYYY-M-D-HH:mm").unix();
      }
      let realStart;
      let realEnd;
      if (this.data.type == "room") {
        this.bus.$emit("loading", true);
        if (this.time.start < startTimeLocalUnix) {
          realStart = startTimeLocalUnix;
        } else {
          realStart = this.time.start;
        }
        if (this.time.end > endTimeLocalUnix) {
          realEnd = endTimeLocalUnix;
        } else {
          realEnd = this.time.end;
        }

        let room = [];
        room.push(this.data.resourceId);
        let attendees = [];
        this.participants.map((item) => {
          attendees.push(item.email);
        });
        console.log("====this.bookingDeletegate =====", this.bookingDeletegate);
        // let isEmptyObj = Object.keys(this.bookingDeletegate).length == 0;//之前是判断booking for是否为空，空的话就calendarId就传空，不为空的话calendarId传当前userEmail，但逻辑改了一下，UI上Booking For默认展示自己，所以this.bookingDeletegate默认是自己，只是属性中只有name没有id，故改成下面判断有无id的逻辑来实现

        let calendarId;
        // 如果bookingDelegate选了,这个时候calendarId传空
        if (this.bookingDeletegate.id) {
          calendarId = "";
        } else {
          // 如果bookingDelegate没选,则这个时候calendarId传当前登录用户的userEmail
          calendarId = localStorage.userEmail;
        }
        // let calendarId = isEmptyObj ? localStorage.userEmail : "";
        this.$axios({
          method: "POST",
          url: "/m/spaces/insertEvent/",
          data: {
            isOnlineMeeting: this.addGoogle ? 1 : 0, //是否显示email phone之类得详细信息
            calendarId: calendarId, //订房间的人的email
            rooms: room, //房间的resourceId, 数组
            meetingId: localStorage.meetingIdMap, //campus的id
            // start: this.time.start, //开始时间戳
            // end: this.time.end, //结束时间戳
            start: realStart, //开始时间戳
            end: realEnd, //结束时间戳
            summary: this.summary, //标题
            description: this.description, //详情
            attendees: attendees, //参会人员的email, 数组
            delegateFor: this.bookingDeletegate.id,
          },
        })
          .then((res) => {
            this.bus.$emit("loading", false);
            if (res.data.result == "failed") {
              this.$confirm({
                title: "Confirmation",
                message: res.data.message,
                show: true,
              });
              return;
            }
            this.$message.success("Success");
            var arr = Object.keys(this.bookingDeletegate);
            console.log("arr!!!!!!!!!!!!!!!", arr);
            console.log("bookingDeletegate!!!!!!!!!!!!!!!", this.bookingDeletegate);
            if (arr.length == 0) {
              this.setBookingType("Meetings");
            } else {
              if (this.bookingDeletegate.name == localStorage.userName) {
                this.setBookingType("Meetings");
              } else {
                this.setBookingType("Others");
              }
            }
            this.$router.push({
              name: "booking",
              params: { start: this.time.start, end: this.time.end },
            });
            // if (window.gtag) {
            //   window.gtag("event", "Space", {
            //     event_category: "Space Book Succeed",
            //     event_label: this.data.name,
            //     send_to: localStorage.getItem("googleAnaId") || "",
            //     dimension1: localStorage.getItem("campusName") || ""
            //   });
            // }
            this.$gtag.event('Space', {
              event_category: 'Space Book Succeed',
              event_label: this.data.name,
              send_to: localStorage.getItem("googleAnaId") || "",
              dimension1: localStorage.getItem("campusName") || "",
            });
          })
          .catch((err) => {
            this.bus.$emit("loading", false);
            console.log(err);
          });
      } else if (this.data.type == "desk") {
        console.log("Desk----time", this.time);
        console.log("startTime", startTime);
        console.log("endTime", endTime);

        if (
          this.time.start < startTimeLocalUnix ||
          (!isNaN(startTime) && startTime < startTimeLocalUnix)
        ) {
          realStart = startTimeLocalUnix;
        } else {
          realStart = isNaN(startTime) ? this.time.start : startTime;
        }
        if (this.time.end > endTimeLocalUnix || (!isNaN(endTime) && endTime > endTimeLocalUnix)) {
          console.log('zou1');
          realEnd = this.time.end;
        } else {
          console.log('zou2');
          realEnd = isNaN(endTime) ? this.time.end : endTime;
        }

        let deskEndTimeZero = moment(this.time.end, "X").set({ second: 0 });
        let deskEndTimeZeroMinerOneSecond = moment(deskEndTimeZero, "X").add(-1, "seconds").unix();
        // console.log('deskEndTimeZeroMinerOneSecond',deskEndTimeZeroMinerOneSecond);
        if (this.bookingDeletegate.id) {
          // if (window.gtag) {
          //   window.gtag("event", "Book For", {
          //     event_category: "Book a desk for someone",
          //     event_label: this.bookingDeletegate.name,
          //     send_to: localStorage.getItem("googleAnaId") || "",
          //     dimension1: localStorage.getItem("campusName") || ""
          //   });
          // }
          this.$gtag.event('Book For', {
            event_category: 'Book a desk for someone',
            event_label: this.bookingDeletegate.name,
            send_to: localStorage.getItem("googleAnaId") || "",
            dimension1: localStorage.getItem("campusName") || "",
          });
        }
        this.bus.$emit("loading", true);
        this.$axios({
          // method: 'get',
          // url: '/m/desk/reserveSeat',
          method: this.$usev2 ? "post" : "get",
          url: this.$usev2 ? "/m/v2/desk/reserveSeat" : "/m/desk/reserveSeat",
          data: {
            meetingId: localStorage.meetingIdMap,
            seatId: this.data.resourceId,
            // start: isNaN(startTime) ? this.time.start : startTime,
            // end: isNaN(endTime) ? this.time.end : endTime,
            start: realStart,
            end: realEnd,
            // end: isNaN(endTime) ? deskEndTimeZeroMinerOneSecond : endTime,
            delegateFor: this.bookingDeletegate.id ? this.bookingDeletegate.id : "",
          },
        })
          .then((res) => {
            this.bus.$emit("loading", false);
            if (res.data.result == "failed") {
              this.$confirm({
                title: "Confirmation",
                message: res.data.message,
                show: true,
              });
              return;
            }
            this.$message.success("Success");
            if (this.showDelegateFunction) {
              console.log("111111111111111");
              var arr = Object.keys(this.bookingDeletegate);
              console.log("arr11", arr);
              if (arr.length == 0) {
                this.setBookingType("Desks / Offices");
              } else {
                if (this.bookingDeletegate.id) {
                  this.setBookingType("Others");
                } else {
                  this.setBookingType("Desks / Offices");
                }
                // this.setBookingType('Others');
              }
            } else {
              console.log("222222222222222");
              var arr = Object.keys(this.bookingDeletegate);
              if (arr.length == 0) {
                this.setBookingType("Desks");
              } else {
                if (this.bookingDeletegate.id) {
                  this.setBookingType("Others");
                } else {
                  this.setBookingType("Desks");
                }
              }
            }
            this.$router.push({
              name: "booking",
              params: { start: this.time.start, end: this.time.end },
            });
            // if (window.gtag) {
              // window.gtag("event", "Space ", {
              //   event_category: "Space Book Succeed",
              //   event_label: this.data.name,
              //   send_to: localStorage.getItem("googleAnaId") || "",
              //   dimension1: localStorage.getItem("campusName") || ""
              // });
              this.$gtag.event('Space', {
                event_category: 'Space Book Succeed',
                event_label: this.data.name,
                send_to: localStorage.getItem("googleAnaId") || "",
                dimension1: localStorage.getItem("campusName") || "",
              });
              if (this.bookingDeletegate.id) {
                // window.gtag("event", "Space ", {
                //   event_category: "Space Delegate Booking",
                //   event_label: this.data.name,
                //   send_to: localStorage.getItem("googleAnaId") || "",
                //   dimension1: localStorage.getItem("campusName") || ""
                // });
                this.$gtag.event('Space', {
                  event_category: 'Space Delegate Booking',
                  event_label: this.data.name,
                  send_to: localStorage.getItem("googleAnaId") || "",
                  dimension1: localStorage.getItem("campusName") || "",
                });
              }
            // }
          })
          .catch((err) => {
            this.bus.$emit("loading", false);
            console.log(err);
          });
      }
    },
    backToSearchListDefault() {
      let obj = {};
      obj.start = "";
      obj.end = "";
      this.$emit("backToSearchList", obj);
    },
    //弃用
    backToSearchList(obj) {
      this.$emit("backToSearchList", obj);
    },
    //add microsoft team 逻辑
    initAddLogic() {
      let that = this;
      this.summary = localStorage.userName + "'s Meeting";
      this.description = "";
      if (JSON.parse(localStorage.config).onlineMeeting.length > 0) {
        this.onlineMeeting = JSON.parse(localStorage.config).onlineMeeting;
        this.onlineMeeting.map((item, index) => {
          that.$set(that.onlineMeeting[index], "checked", false);
          // item.checked = false; //踩坑！！！！！！！！如果要用$set响应式修改数据，如果初始数据中没这个属性，也要用$set的方式添加，否则$set修改视图也不会刷新
        });
      } else {
        let lable = "Add Zoom Link";
        let type = "";
        if (this.client == "adobe") {
          lable = "Add Teams";
          type = "api";
        } else if (this.client == "CX Campus") {
          lable = "Add Google Meet";
        }
        this.onlineMeeting = [
          {
            name: "",
            label: lable,
            type: type,
            checked: false,
          },
        ];
      }
    },
  },
  created() {
    this.client = localStorage.getItem("client");
    this.showDelegateFunction = showDelegate;
    this.initBookingFor();
    this.initAddLogic();
    this.summary = localStorage.userName + "'s Meeting";
    if (this.client == "WarnerMedia") {
      this.deskBtnLabel = "Today";
    } else {
      this.deskBtnLabel = "Today > End of Day";
    }
  },
  mounted() {
    // console.log("type", this.data.type);
    console.log("showInfo！！！！！！！！！！！！！！！！", this.showInfo);
    console.log("showDeskInfo！！！！！！！！！！！！！！！！", this.showDeskInfo);
    let timezone = localStorage.getItem("timezone");
    if (timezone) {
      this.startTimeSpecific = moment(this.time.start, "X").tz(timezone).format("MMM D, h:mma");
      this.endTimeSpecific = moment(this.time.end, "X")
        .tz(timezone)
        .add(-1, "seconds")
        .format("MMM D, h:mma");
    } else {
      this.startTimeSpecific = moment(this.time.start, "X").format("MMM D, h:mma");
      this.endTimeSpecific = moment(this.time.end, "X").add(-1, "seconds").format("MMM D, h:mma");
    }
    // this.startTimeSpecific = moment(this.time.start, "X").format("MMM D, h:mma");
    // this.endTimeSpecific = moment(this.time.end, "X").format("MMM D, h:mma");
    let minutes;
    if (this.data.type == "room") {
      if (!this.data.busy_time.length > 0) {
        minutes = 120;
      } else {
        minutes = moment(this.data.busy_time[0].start, "X").diff(
          moment(this.time.start, "X"),
          "minutes"
        );
      }
      console.log("minutes2222222222", minutes);
      if (minutes >= 0 && minutes < 30) {
        this.roomBtn = [];
        this.showInfo = true;
      } else if (minutes == 30) {
        this.roomBtn = [{ label: "30m", value: 30 }];
        this.showInfo = false;
      } else if (minutes == 60) {
        this.roomBtn = [
          { label: "30m", value: 30 },
          { label: "60m", value: 60 },
        ];
        this.showInfo = false;
      } else if (minutes == 90) {
        this.roomBtn = [
          { label: "30m", value: 30 },
          { label: "60m", value: 60 },
          { label: "90m", value: 90 },
        ];
        this.showInfo = false;
      } else if (minutes == 120) {
        this.roomBtn = [
          { label: "30m", value: 30 },
          { label: "60m", value: 60 },
          { label: "90m", value: 90 },
          { label: "120m", value: 120 },
        ];
        this.showInfo = false;
      } else {
        return;
      }
    } else {
      // desk也展示按钮组 30m 60m 90m 120m (Edwards的desk不要30min)
      if (this.deskShowBtn) {
        if (!this.data.busy_time.length > 0) {
          if (this.client == "Edwards Life Sciences") {
            minutes = 540; //Edwards 可以预订9hr内
          } else {
            minutes = 120; //普通是可以预订2hr内
          }
        } else {
          minutes = moment(this.data.busy_time[0].start, "X").diff(
            moment(this.time.start, "X"),
            "minutes"
          );
        }
        if (minutes == 0) {
          this.deskBtn = [];
          this.showInfo = true;
        } else if (minutes == 30) {
          this.deskBtn = [{ label: "30m", value: 30 }];
          this.showInfo = false;
        } else if (minutes == 60) {
          this.deskBtn = [
            { label: "30m", value: 30 },
            { label: "60m", value: 60 },
          ];
          this.showInfo = false;
        } else if (minutes == 90) {
          this.deskBtn = [
            { label: "30m", value: 30 },
            { label: "60m", value: 60 },
            { label: "90m", value: 90 },
          ];
          this.showInfo = false;
        } else if (minutes == 120) {
          this.deskBtn = [
            { label: "30m", value: 30 },
            { label: "60m", value: 60 },
            { label: "90m", value: 90 },
            { label: "120m", value: 120 },
          ];
          this.showInfo = false;
        } else if (minutes == 540) {
          this.deskBtn = [
            { label: "30m", value: 30 },
            { label: "60m", value: 60 },
            { label: "90m", value: 90 },
            { label: "120m", value: 120 },
            { label: "9hrs", value: 540 },
          ];
          this.showInfo = false;
        } else {
          return;
        }
        if (this.client == "Edwards Life Sciences") {
          let removeThirty = this.deskBtn.filter((item) => {
            return item.value > 30;
          });
          this.deskBtn = removeThirty;
          this.deskBtn.reverse();
        }
      } else {
        // desk展示today > end of day
        if (this.data.busy) {
          this.showInfo = true;
        } else {
          this.showInfo = false;
        }
      }
    }
    // if (this.data.type == 'room') {
    //   if (!this.showDeskInfo) {
    //     this.startTimeSpecific = moment(this.time.start, "X").format("MMM D, h:mma");
    //     this.endTimeSpecific = moment(this.time.end, "X").format("MMM D, h:mma");
    //     console.log("startTimeSpecific:", this.startTimeSpecific);
    //     console.log("endTimeSpecific:", this.endTimeSpecific);
    //   }
    // }

    // room
    // if(this.data.type=='room'){
    //   if (this.time.timeType == 'normal') {
    //     let diff = moment(this.time.end, "X").diff(moment(this.time.start, "X"), "minutes");
    //     if (diff == 30) {
    //       console.log(30)
    //       this.roomBtn = [
    //         {label: '30m', value: 30},
    //       ]
    //     } else if (diff == 60) {
    //       console.log(60)
    //       this.roomBtn = [
    //         {label: '30m', value: 30},
    //         {label: '60m', value: 60},
    //       ]
    //     } else if (diff == 90) {
    //       console.log(90)
    //       this.roomBtn = [
    //         {label: '30m', value: 30},
    //         {label: '60m', value: 60},
    //         {label: '90m', value: 90},
    //       ]
    //     } else if (diff == 120) {
    //       console.log(120)
    //       this.roomBtn = [
    //         {label: '30m', value: 30},
    //         {label: '60m', value: 60},
    //         {label: '90m', value: 90},
    //         {label: '120m', value: 120},
    //       ]
    //     } else {
    //       return
    //     }
    //   }
    //   else {
    //     let label = moment(this.time.start, "X").format("MMM D,h:mm a") + " to " + moment(this.time.end, "X").format("MMM D,h:mm a");
    //     this.roomBtn = [
    //       {label: label, value:''},
    //     ]
    //   }
    // }
    // {//desk
    //   if (this.time.timeType == 'normal') {
    //     this.deskBtnLabel = "Today > End of Day";
    //   }
    //   else {
    //     this.deskBtnLabel = moment(this.time.start, "X").format("MMM D,h:mm a") + " to " + moment(this.time.end, "X").format("MMM D,h:mm a");
    //   }
    // }
  },
};
</script>

<style scoped lang="scss">
.deskBooking {
  .deskBooking-container {
    border: 1px solid #efefef;

    .title {
      //background: $main-blue;
      color: #ffffff;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;

      .title-text {
        width: 75%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    //.title {
    //  background: #4164FD;
    //  color: #ffffff;
    //  height: 40px;
    //  line-height: 40px;
    //  text-align: center;
    //}

    .content {
      min-height: 780px;
      overflow-y: auto;
      overflow-x: hidden;

      .meetingInfo {
        .meetingInfo-input {
          margin: auto;
          width: 70%;
        }

        button {
          display: block;
          margin: 10px auto;
          width: 210px;
        }
      }

      //.active {
      //  background: $btn-color-highlight;
      //  color: #ffffff;
      //}
    }

    .bookInfo {
      //background: $main-blue;
      text-align: center;

      h2 {
        color: #ffffff;
      }

      div {
        color: #ffffff;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
