<template>
  <v-container id="desks" fluid tag="section" class="pa-0 ma-0">
    <v-row class="pa-0 titleContainer">
      <v-col cols="12" md="12" class="ma-0 pa-0 text-style text">
        <div class="title x-title">
          <span v-if="client == 'WarnerMedia'">Search for a Space</span>
          <span v-else>Find a Space</span>
          <i
            v-show="showList"
            tabindex="0"
            class="iconfont icon-chahao"
            aria-label="closeIcon"
            style="float: right; margin-right: 20px; cursor: pointer"
            @click="backToSearchList(true)"
            @keyup.enter="backToSearchList(true)"
          ></i>
        </div>
      </v-col>
    </v-row>
    <div class="desks-content">
      <v-row v-if="!showList" class="ma-0 pa-0">
        <v-col cols="12" md="12" class="ma-0 pa-0">
          <v-card class="ma-0 pa-0" style="border-radius: 0">
            <v-card-title style="background: #f3f3f3">
              <img
                v-if="client == 'WarnerMedia'"
                src="@/assets/images/warner/space.png"
                alt=""
                style="width: 25px; margin-right: 5px"
              />
              <i v-else class="iconfont iconTitle icon-fangkuai"></i>
              <span tabindex="0">TYPE OF SPACE</span>
            </v-card-title>
            <v-tabs
              background-color="#f3f3f3"
              v-model="tab"
              color="transparent"
            >
              <v-tab
                v-show="showRoomButton"
                href="#tab-1"
                style="border-bottom: 0"
                class="ma-0 pa-0"
              >
                <v-btn
                  v-if="client == 'WarnerMedia'"
                  class="ma-1 desks-btn"
                  rounded
                  outlined
                  color="#666666"
                >
                  Meeting Rooms
                </v-btn>
                <v-btn
                  v-else
                  class="ma-1 desks-btn"
                  rounded
                  outlined
                  color="#666666"
                >
                  Room
                </v-btn>
              </v-tab>

              <v-tab v-show="showDeskButton" href="#tab-2" class="ma-0 pa-0">
                <v-btn
                  v-if="client == 'WarnerMedia'"
                  class="ma-1 desks-btn"
                  rounded
                  outlined
                  color="#666666"
                >
                  Desks / Offices
                </v-btn>
                <v-btn
                  v-else
                  class="ma-1 desks-btn"
                  rounded
                  outlined
                  color="#666666"
                >
                  Desks
                </v-btn>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <!--ROOM-->
              <v-tab-item key="1" value="tab-1">
                <!--AVAILABILITY-->
                <v-card flat>
                  <v-card-title>
                    <img
                      v-if="client == 'WarnerMedia'"
                      src="@/assets/images/warner/availablility.png"
                      alt=""
                      style="width: 25px; margin-right: 5px"
                    />
                    <i v-else class="iconfont iconTitle icon-fangkuai"></i>
                    <span v-if="client == 'WarnerMedia'" tabindex="0"
                      >AVAILABLE FOR THE NEXT...</span
                    >
                    <span v-else tabindex="0">AVAILABILITY</span>
                  </v-card-title>
                  <v-card-actions>
                    <v-btn
                      class="ma-1"
                      rounded
                      small
                      outlined
                      v-for="(i, k) in availabilityArr"
                      :key="k"
                      :class="{ 'x-btn-active': i.active === 'active' }"
                      @click="availabilityClick(k)"
                    >
                      {{ i.value }}
                    </v-btn>
                  </v-card-actions>
                  <!--日期选择-->
                  <v-col cols="12" md="12" v-show="showDatePicker">
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-row>
                          <v-col cols="12" md="6" @keyup.enter="menu1 = true">
                            <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  id="roomStartDate"
                                  v-model="dateText"
                                  label="Start Date*"
                                  v-bind="attrs"
                                  @blur="parseDateNew(dateText, 'date')"
                                  v-on="on"
                                  autocomplete="off"
                                  clearable
                                  @click:clear="date = null"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                id="roomStartDatePicker"
                                onfocus="console.log('获得焦点')"
                                v-model="date"
                                no-title
                                @input="dateInput"
                                :allowed-dates="allowedDates"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: -8px;
                            "
                          >
                            <Time
                              v-model="dateStartTime"
                              id="roomStartTime"
                              format="hh:mm a"
                              :minute-interval="30"
                              manual-input
                              placeholder="Start Time*"
                              aria-label="Start Time"
                              :input-width="'100%'"
                            ></Time>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6" @keyup.enter="menu2 = true">
                            <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateEndText"
                                  label="End Date*"
                                  v-bind="attrs"
                                  @blur="parseDateNew(dateEndText, 'dateEnd')"
                                  v-on="on"
                                  autocomplete="off"
                                  clearable
                                  @click:clear="dateEnd = null"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dateEnd"
                                no-title
                                @input="dateEndInput"
                                :allowed-dates="allowedDates"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: -8px;
                            "
                          >
                            <Time
                              v-model="dateEndTime"
                              format="hh:mm a"
                              :minute-interval="30"
                              manual-input
                              placeholder="End Time*"
                              aria-label="End Time"
                              :input-width="'100%'"
                            ></Time>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
                <!--CAPACITY-->
                <v-card flat>
                  <v-card-title style="background: #f3f3f3">
                    <img
                      v-if="client == 'WarnerMedia'"
                      src="@/assets/images/warner/capacity.png"
                      alt=""
                      style="width: 25px; margin-right: 5px"
                    />
                    <i v-else class="iconfont iconTitle icon-fangkuai"></i>
                    <span tabindex="0">CAPACITY</span>
                  </v-card-title>
                  <v-card-actions style="background: #f3f3f3">
                    <v-btn
                      class="ma-2"
                      rounded
                      small
                      outlined
                      v-for="(i, k) in capacity"
                      :key="k"
                      :class="{ 'x-btn-active': i.active === 'active' }"
                      @click="capacityClick(k)"
                    >
                      {{ i.label }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <!--AMENITIES-->
                <v-card flat>
                  <v-card-title>
                    <img
                      v-if="client == 'WarnerMedia'"
                      src="@/assets/images/warner/emergency-assistance.png"
                      alt=""
                      style="width: 25px; margin-right: 5px"
                    />
                    <i v-else class="iconfont iconTitle icon-fangkuai"></i>
                    <span v-if="client == 'WarnerMedia'" tabindex="0"
                      >FEATURES</span
                    >
                    <span v-else-if="client == 'EA'">EQUIPMENT</span>
                    <span v-else tabindex="0">AMENITIES</span>
                  </v-card-title>
                  <v-btn
                    class="ma-2"
                    rounded
                    small
                    outlined
                    :title="i.value"
                    v-for="(i, k) in roomAmenities"
                    :key="k"
                    :class="{ 'x-btn-active': i.active === 'active' }"
                    @click="roomAmenitiesClick(k)"
                  >
                    {{ i.value }}
                  </v-btn>
                </v-card>
              </v-tab-item>
              <!--DESKS-->
              <v-tab-item key="2" value="tab-2">
                <!--AVALABLE FOR-->
                <v-card flat>
                  <v-card-title>
                    <img
                      v-if="client == 'WarnerMedia'"
                      src="@/assets/images/warner/availablility.png"
                      alt=""
                      style="width: 25px; margin-right: 5px"
                    />
                    <i v-else class="iconfont iconTitle icon-fangkuai"></i>
                    <span>AVAILABLE FOR</span>
                  </v-card-title>
                  <div class="availBtns">
                    <v-btn
                      v-for="(item, index) in availBtns"
                      :key="'availBtns' + index"
                      class="ma-3 desks-btn"
                      :class="{ active: item.active === 'active' }"
                      rounded
                      outlined
                      @click="clickAvailContent(item)"
                    >
                      {{ item.label }}
                    </v-btn>
                  </div>
                  <div class="availContents">
                    <div v-show="showContent1">
                      <p class="annotation">
                        Find a desk that's available between now and the end of
                        today
                      </p>
                    </div>
                    <div v-show="showContent2">
                      <p class="annotation" v-if="client == 'HPE Campus'">
                        Find desks available during a certain timeframe. Desk
                        reservations can be up to 24 hours long, and the booking
                        can be up to
                        {{ advanceBookingLimit }}-days in the future
                      </p>
                      <p class="annotation" v-else-if="client == 'Clorox'">
                        Find a desk that is available during certain hours on a
                        certain day. Hourly desk reservations may be made up to
                        {{ advanceBookingLimit }} days in advance. Note: Hourly
                        reservations may only be made for a single day at a
                        time.
                      </p>
                      <p class="annotation" v-else-if="client == 'Metlife'">
                        Find a desk that’s available during certain hours on a
                        certain day (may not cross over multiple days).
                      </p>
                      <p class="annotation" v-else-if="client == 'NatWest'">
                        Find a desk that's available during certain hours on a
                        certain day (may not cross over multiple days).
                      </p>
                      <p class="annotation" v-else-if="client == 'Warnermedia'">
                        Find a workstation or office for a specific date range.
                        There are location-specific limits to how far in advance
                        and how many days the reservation can be - no results
                        will be returned if you exceed these limits.
                      </p>
                      <p class="annotation" v-else>
                        Find desk available during a certain time frame. Desk
                        booking is only available up to
                        {{ advanceBookingLimit }} days in the future
                      </p>
                      <v-col cols="12" md="12" style="margin-top: 10px">
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-row>
                              <v-col
                                cols="12"
                                md="6"
                                @keyup.enter="menuHourlyStartDate = true"
                              >
                                <!--开始日期-->
                                <v-menu
                                  ref="menuHourlyStartDate"
                                  v-model="menuHourlyStartDate"
                                  :close-on-content-click="false"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="dateHourlyText"
                                      label="Start Date*"
                                      v-bind="attrs"
                                      @blur="
                                        parseDateNew(
                                          dateHourlyText,
                                          'dateHourly'
                                        )
                                      "
                                      v-on="on"
                                      autocomplete="off"
                                      clearable
                                      aria-label="Start Date"
                                      @click:clear="dateHourly = null"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dateHourly"
                                    no-title
                                    aria-label="Start Date"
                                    @input="dateHourlyInput"
                                    :allowed-dates="allowedDates"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                                style="
                                  display: flex;
                                  align-items: center;
                                  margin-top: -8px;
                                "
                              >
                                <!--开始时间-->
                                <!--<v-menu-->
                                <!--  ref="menuHourlyStartTime"-->
                                <!--  v-model="menuHourlyStartTime"-->
                                <!--  :close-on-content-click="false"-->
                                <!--  :nudge-right="40"-->
                                <!--  :return-value.sync="time"-->
                                <!--  transition="scale-transition"-->
                                <!--  offset-y-->
                                <!--  max-width="290px"-->
                                <!--  min-width="290px"-->
                                <!--&gt;-->
                                <!--  <template v-slot:activator="{ on, attrs }">-->
                                <!--    <v-text-field-->
                                <!--      color="rgb(238 44 84)"-->
                                <!--      v-model="hourlyStartTime"-->
                                <!--      label="*Start Time"-->
                                <!--      prepend-icon="mdi-clock-time-four-outline"-->
                                <!--      readonly-->
                                <!--      v-bind="attrs"-->
                                <!--      v-on="on"-->
                                <!--    ></v-text-field>-->
                                <!--  </template>-->
                                <!--  <v-time-picker-->
                                <!--    format="ampm"-->
                                <!--    v-if="menuHourlyStartTime"-->
                                <!--    v-model="hourlyStartTime"-->
                                <!--    full-width-->
                                <!--    @click:minute="$refs.menuHourlyStartTime.save(hourlyStartTime)"-->
                                <!--  ></v-time-picker>-->
                                <!--</v-menu>-->
                                <Time
                                  v-model="hourlyStartTime"
                                  format="hh:mm a"
                                  :minute-interval="30"
                                  manual-input
                                  placeholder="Start Time*"
                                  aria-label="Start Time"
                                  :input-width="'100%'"
                                ></Time>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-row>
                              <v-col
                                cols="12"
                                md="6"
                                @keyup.enter="menuHourlyEndDate = true"
                              >
                                <!--结束日期-->
                                <v-menu
                                  ref="menuHourlyEndDate"
                                  v-model="menuHourlyEndDate"
                                  :close-on-content-click="false"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="dateHourlyEndText"
                                      label="End Date*"
                                      aria-label="End Date"
                                      v-bind="attrs"
                                      @blur="
                                        parseDateNew(
                                          dateHourlyEndText,
                                          'dateHourlyEnd'
                                        )
                                      "
                                      v-on="on"
                                      autocomplete="off"
                                      clearable
                                      @click:clear="dateHourlyEnd = null"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dateHourlyEnd"
                                    aria-label="End Date"
                                    no-title
                                    @input="dateHourlyEndInput"
                                    :allowed-dates="allowedDates"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                                style="
                                  display: flex;
                                  align-items: center;
                                  margin-top: -8px;
                                "
                              >
                                <!--结束时间-->
                                <Time
                                  v-model="hourlyEndTime"
                                  @click="test"
                                  v-if="showTime"
                                  format="hh:mm a"
                                  :minute-interval="30"
                                  manual-input
                                  placeholder="End Time*"
                                  aria-label="End Time"
                                  :input-width="'100%'"
                                ></Time>
                                <!--<v-menu-->
                                <!--  ref="menuHourlyEndTime"-->
                                <!--  v-model="menuHourlyEndTime"-->
                                <!--  :close-on-content-click="false"-->
                                <!--  :nudge-right="40"-->
                                <!--  :return-value.sync="time"-->
                                <!--  transition="scale-transition"-->
                                <!--  offset-y-->
                                <!--  max-width="290px"-->
                                <!--  min-width="290px"-->
                                <!--&gt;-->
                                <!--  <template v-slot:activator="{ on, attrs }">-->
                                <!--    <v-text-field-->
                                <!--      color="rgb(238 44 84)"-->
                                <!--      v-model="hourlyEndTime"-->
                                <!--      label="*End Time"-->
                                <!--      prepend-icon="mdi-clock-time-four-outline"-->
                                <!--      readonly-->
                                <!--      v-bind="attrs"-->
                                <!--      v-on="on"-->
                                <!--    ></v-text-field>-->
                                <!--  </template>-->
                                <!--  <v-time-picker-->
                                <!--    format="ampm"-->
                                <!--    v-if="menuHourlyEndTime"-->
                                <!--    v-model="hourlyEndTime"-->
                                <!--    full-width-->
                                <!--    @click:minute="$refs.menuHourlyEndTime.save(hourlyEndTime)"-->
                                <!--  ></v-time-picker>-->
                                <!--</v-menu>-->
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                    <div v-show="showContent3">
                      <!-- <p class="annotation" v-if="client=='HPE Campus'">
                        Find desks available during a certain timeframe. Desk reservations can be up
                        to 24 hours at a time, and the booking can be up to {{
                          advanceBookingLimit
                        }}-days in the future.
                      </p>    -->
                      <p class="annotation" v-if="client == 'HPE Campus'">
                        Find desks available during a certain timeframe. Desk
                        reservations can be made up to
                        {{ advanceBookingLimit }} days in the future and for no
                        longer than 24 hours at a time.
                      </p>
                      <p class="annotation" v-else-if="client == 'Clorox'">
                        Desk reservations may be made up to
                        {{ advanceBookingLimit }} days in advance.
                      </p>
                      <p class="annotation" v-else-if="client == 'Metlife'">
                        Find a desk that’s available during a certain date
                        range. Maximum of
                        {{ advanceBookingLimit }} Days. May start today or in
                        the future.
                      </p>
                      <p class="annotation" v-else-if="client == 'WarnerMedia'">
                        Find a workstation or office for a specific date range.
                        There are location-specific limits to how far in advance
                        and how many days the reservation can be - no results
                        will be returned if you exceed these limits.
                      </p>
                      <p class="annotation" v-else>
                        Find desk available during a certain time frame. Desk
                        booking is only available up to
                        {{ advanceBookingLimit }} days in the future
                      </p>
                      <v-col cols="12" md="12" style="margin-top: 10px">
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-row>
                              <v-col
                                cols="12"
                                md="6"
                                @keyup.enter="menuDayStartDate = true"
                              >
                                <v-menu
                                  ref="menuDayStartDate"
                                  v-model="menuDayStartDate"
                                  :close-on-content-click="false"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="dateDayText"
                                      label="Start Date*"
                                      v-bind="attrs"
                                      aria-label="Start Date"
                                      @blur="
                                        parseDateNew(dateDayText, 'dateDay')
                                      "
                                      v-on="on"
                                      autocomplete="off"
                                      clearable
                                      @click:clear="dateDay = null"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dateDay"
                                    aria-label="Start Date"
                                    no-title
                                    @input="dateDayInput"
                                    :allowed-dates="allowedDates"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                                @keyup.enter="menuDayEndDate = true"
                              >
                                <v-menu
                                  ref="menuDayEndDate"
                                  v-model="menuDayEndDate"
                                  :close-on-content-click="false"
                                  readonly
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="dateDayEndText"
                                      label="End Date*"
                                      v-bind="attrs"
                                      @blur="
                                        parseDateNew(
                                          dateDayEndText,
                                          'dateDayEnd'
                                        )
                                      "
                                      v-on="on"
                                      autocomplete="off"
                                      aria-label="End Date"
                                      clearable
                                      @click:clear="dateDayEnd = null"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dateDayEnd"
                                    no-title
                                    @input="dateDayEndInput"
                                    aria-label="End Date"
                                    :allowed-dates="allowedDates"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                  </div>
                </v-card>
                <!--DESKAMENITIES-->
                <v-card flat style="background: #f3f3f3">
                  <v-card-title>
                    <img
                      v-if="client == 'WarnerMedia'"
                      src="@/assets/images/warner/emergency-assistance.png"
                      alt=""
                      style="width: 25px; margin-right: 5px"
                    />
                    <i v-else class="iconfont iconTitle icon-fangkuai"></i>
                    <span v-if="client == 'WarnerMedia'">FEATURES</span>
                    <span v-else-if="client == 'EA'">EQUIPMENT</span>
                    <span v-else>AMENITIES</span>
                  </v-card-title>
                  <v-btn
                    class="ma-2"
                    rounded
                    outlined
                    v-for="(i, k) in deskAmenities"
                    :key="k"
                    :class="{ 'x-btn-active': i.active === 'active' }"
                    @click="deskAmenitiesClick(k)"
                  >
                    <div
                      style="
                        max-width: 300px;
                        word-wrap: break-word;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ i.value }}
                    </div>
                  </v-btn>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
            <v-btn
              rounded
              small
              class="ma-2 searchBtn x-btn"
              @click="searchList"
              >Search</v-btn
            >
            <v-btn rounded small outlined @click="clearAll">Clear</v-btn>
          </v-card>
        </v-col>
      </v-row>
      <!--search list-->
      <v-row v-if="showList" class="ma-0 pa-0">
        <v-col col="12" class="ma-0 pa-0">
          <v-card class="ma-0 pa-3">
            <v-card-title class="ma-0 pa-0">
              <span>{{ searchListTitle }}</span>
            </v-card-title>
            <!--按钮-->
            <div class="availBtns">
              <v-btn
                v-for="(item, index) in searchBtns"
                :key="'searchBtns' + index"
                class="ma-3 desks-btn"
                :class="{ active: item.active }"
                rounded
                outlined
                @click="clickSearchBtn(item)"
              >
                {{ item.label }}
              </v-btn>
              <v-btn
                v-for="(item, index) in buildings"
                :key="'buildings' + index"
                class="ma-3"
                :class="{ active: item.active }"
                rounded
                outlined
                @click="clickBuilding(item)"
              >
                {{ item.label }}
              </v-btn>
              <v-btn
                v-for="(item, index) in floors"
                v-show="showFloors"
                :key="'floors' + index"
                class="ma-3"
                :class="{ active: item.active }"
                rounded
                outlined
                @click="clickFloor(item)"
              >
                {{ item.label }}
              </v-btn>
              <!--</div>-->
              <!--提示内容-->
              <p style="margin: 16px 0">{{ listNote }}</p>
              <!--列表-->
              <deskList
                v-if="list.length > 0"
                :data="list"
                v-on:clickCanlender="clickCanlender"
                v-on:acceptMap="acceptMap"
              ></deskList>
              <div v-else class="noResults" tabindex="0" aria-label="No result">
                no result
                <p
                  style="font-weight: 100; font-size: 18px"
                  v-if="client == 'WarnerMedia'"
                >
                  We’re sorry. There are no spaces of this type on this floor
                  fitting your timeframe or criteria. Please select another
                  floor or modify your search.
                </p>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import deskList from "@/views/dashboard/componentsHa/deskList";
import Time from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "Search",
  props: {
    showList: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: [],
    },
    buildFloor: {
      type: Array,
      default: [],
    },
    // 用户默认的building和floor
    bfData: {
      type: Object,
    },
    defaultBfData: {
      type: Object,
    },
  },
  components: {
    deskList,
    Time,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add " : "Edit ";
    },
  },
  watch: {
    // 用户默认building和floor
    bfData(newVal, oldVal) {
      this.buildingName = newVal.buildingName;
      this.floorName = newVal.floorName;
      this.currentFloor = newVal.currentFloor;
      this.listNote =
        "Location: " + this.buildingName + " on " + this.floorName;
    },
    buildFloor(newVal, oldVal) {
      console.log("buildFloor:", newVal);
      this.formatBuildFloor(newVal);
    },
    // 开始时间（ROOM）
    date(val) {
      this.dateText = this.formatDate(this.date);
      this.dateInput();
    },
    // 结束时间（ROOM）
    dateEnd(val) {
      this.dateEndText = this.formatEndDate(this.dateEnd);
      this.dateEndInput();
    },
    // 开始日期（DESK HOURLY）
    dateHourly(val, oldVal) {
      this.dateHourlyText = this.formatDate(this.dateHourly);
      this.dateHourlyInput();
    },
    // 结束日期（DESK HOURLY）
    dateHourlyEnd(val) {
      this.dateHourlyEndText = this.formatEndDate(this.dateHourlyEnd);
      this.dateHourlyEndInput();
    },
    // 开始日期（DESK START/END OF DATE）
    dateDay(val, oldVal) {
      this.dateDayText = this.formatDate(this.dateDay);
      this.dateDayInput();
    },
    // 结束日期（DESK START/END OF DATE）
    dateDayEnd(val) {
      this.dateDayEndText = this.formatEndDate(this.dateDayEnd);
      this.dateDayEndInput();
    },
    // menu1(val,oldVal){
    //   console.log('val',val);
    //  if(val){
    //    this.showD = true;
    //    // console.log('tabindex',document.querySelector('[tabindex="-1"]'));
    //    // document.querySelector('[tabindex="-1"]').focus();
    //    var div = document.getElementById("");
    //    console.log('div',div);
    //    this.$nextTick(()=>{
    //      div.tabIndex = -1;
    //      div.focus();
    //    })
    //  }
    // }
  },
  data() {
    return {
      timezone: null,
      showD: false,
      showTime: true, //desk horly time show
      showRoomButton: true,
      showDeskButton: true,
      btnColor: "",
      advanceBookingLimit: 30, //规定room/desk 可以预定的时长，默认30天，后台有值就用后台的值
      advanceRoomBookingLimit: 30, //规定room/desk 可以预定的时长，默认30天，后台有值就用后台的值
      client: "",
      showFloors: false, //显示楼层
      buildingName: "",
      floorName: "",
      tab: "tab-1",
      tab2: "tab-2",
      timeType: "", //点击search按钮时选择的时间类型 normal默认 custom自定义
      // availabilityVal: '',
      showDatePicker: false,
      availBtns: [],
      // availBtns: [
      //   {label: 'Today > End of Day', value: 1, active: 'active'},
      //   {label: 'Hourly', value: 2, active: ''},
      //   {label: 'Start/End of Date', value: 3, active: 'false'},
      // ],
      // time: [
      //   {
      //     value: 'Today > End of Day',
      //     active: ''
      //   },
      //   {
      //     value: 'Start/End of Date',
      //     active: ''
      //   },
      // ],
      start: "",
      end: "",
      showContent1: true,
      showContent2: false,
      showContent3: false,
      // ROOM
      date: "", //开始日期
      dateEnd: "", //结束日期
      dateStartTime: "12:00 am", //开始时间
      dateEndTime: "12:00 am", //结束时间
      dateText: "",
      dateEndText: "",
      menu1: false,
      menu2: false,
      availabilityArr: [
        {
          value: "15m",
          active: "active",
        },
        {
          value: "30m",
          active: "",
        },
        {
          value: "1hr",
          active: "",
        },
        {
          value: "2hr",
          active: "",
        },
        {
          value: "Custom",
          active: "",
        },
      ],
      capacity: [
        // {
        //   value: 1,
        //   label: '1',
        //   active: 'active'
        // },
        {
          value: 2,
          label: "2",
          active: "",
        },
        {
          value: 4,
          label: "4",
          active: "",
        },
        {
          value: 6,
          label: "6",
          active: "",
        },
        {
          value: 8,
          label: "8+",
          active: "",
        },
      ],
      // amenities: [
      //   {
      //     value: 'Table',
      //     active: ''
      //   },
      //   {
      //     value: 'Teams & Cisco Speaker',
      //     active: ''
      //   },
      //   {
      //     value: 'Cisco Speaker Phone',
      //     active: ''
      //   },
      //   {
      //     value: 'Teams',
      //     active: ''
      //   },
      //   {
      //     value: 'Shared Workspace',
      //     active: ''
      //   },
      //   {
      //     value: 'Room',
      //     active: ''
      //   },
      //   {
      //     value: 'MirrorOP',
      //     active: ''
      //   },
      //   {
      //     value: 'Docking Station',
      //     active: ''
      //   },
      //   {
      //     value: 'Coference',
      //     active: ''
      //   },
      //   {
      //     value: 'File Room',
      //     active: ''
      //   },
      //   {
      //     value: 'Desk Phone',
      //     active: ''
      //   },
      //   {
      //     value: 'HD File Room',
      //     active: ''
      //   },
      //   {
      //     value: 'Training',
      //     active: ''
      //   },
      //   {
      //     value: 'Lab',
      //     active: ''
      //   },
      //   {
      //     value: 'Storage Room',
      //     active: ''
      //   },
      //   {
      //     value: 'Project Room',
      //     active: ''
      //   },
      // ],
      roomAmenities: [],
      // DESK（HOURLY）
      dateHourly: "", //开始日期（picker）
      dateHourlyEnd: "", //结束日期（picker）
      dateHourlyText: "", //开始日期（text）
      dateHourlyEndText: "", //结束日期（text）
      hourlyStartTime: "12:00 am", //开始时间
      hourlyEndTime: "12:00 am", //结束时间
      menuHourlyStartDate: false,
      menuHourlyStartTime: false,
      menuHourlyEndDate: false,
      menuHourlyEndTime: false,
      // DESK（START/END OF DATE）
      dateDay: "", //开始日期(picker)
      dateDayEnd: "", //结束日期(picker)
      dateDayText: "", //开始日期(text)
      dateDayEndText: "", //结束日期(text)
      menuDayStartDate: false,
      menuDayEndDate: false,
      deskAmenities: [
        // {
        //   value: 'Docking Station',
        //   active: ''
        // },
        // {
        //   value: 'HD File Room',
        //   active: ''
        // },
        // {
        //   value: 'Dual Monitors',
        //   active: ''
        // },
        // {
        //   value: 'Desk Phone',
        //   active: ''
        // },
        // {
        //   value: 'Lab',
        //   active: ''
        // },
        // {
        //   value: 'Height Adjustable Table',
        //   active: ''
        // }
      ],
      // search list
      searchBtns: [
        { label: "Default", type: "default", value: 1, active: true },
        {
          label: "By Building/Floor",
          type: "buildings",
          value: 2,
          active: false,
        },
      ],
      searchListTitle: "Room Availability",
      listNote: "",
      currentBuilding: {}, //当前选中的building
      buildingsAndFloorsData: [], //所有building和floor的data
      buildings: [
        // {label: 'building1',type:'building', id: 1, active: false},
        // {label: 'building2', type:'building',id: 2, active: false},
      ],
      floors: [],
      deskListData: [
        // {name:'An American Pickle',imgSrc:require('@/assets/images/test/x-desk.png'),building:'01',floor:'01',capacity:17},
        // {name:'An American Pickle',imgSrc:require('@/assets/images/test/x-desk.png'),building:'01',floor:'01',capacity:17},
        // {name:'An American Pickle',imgSrc:require('@/assets/images/test/x-desk.png'),building:'01',floor:'01',capacity:17},
        // {name:'An American Pickle',imgSrc:require('@/assets/images/test/x-desk.png'),building:'01',floor:'01',capacity:17},
      ],
      filterItem: {}, //条件筛选
    };
  },
  methods: {
    getClient() {
      this.getClientData()
        .then((res) => {
          this.client = res.data.Client;
          if (res.data.Client == "Clorox") {
            this.client = "clorox";
          } else {
            this.client = "normal";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 格式化Building和Floor
    formatBuildFloor(data) {
      console.log("formatBuildFloor:", data);
      this.buildingsAndFloorsData = [];
      data.map((item) => {
        let obj = {};
        obj.label = item.buildingName;
        obj.type = "building";
        obj.id = item.id;
        obj.active = false;
        obj.children = [];
        item.floors.map((desk) => {
          if (
            item.buildingName == this.buildingName &&
            desk.name == this.floorName
          ) {
            this.currentFloor = desk;
          }
          let obj2 = {};
          obj2.externalId = desk.externalId;
          obj2.height = desk.height;
          obj2.hideOnDeskSearch = desk.hideOnDeskSearch;
          obj2.hideOnRoomSearch = desk.hideOnRoomSearch;
          obj2.hideOnRoomSearch = desk.hideOnRoomSearch;
          obj2.id = desk.id;
          obj2.lastModified = desk.lastModified;
          obj2.max_zoom = desk.max_zoom;
          obj2.label = desk.name;
          obj2.resourceId = desk.resourceId;
          obj2.url = desk.url;
          obj2.width = desk.width;
          obj2.type = "desk";
          obj2.active = false;
          obj.children.push(obj2);
        });
        this.buildingsAndFloorsData.push(obj);
      });
    },
    // 日期不能选当日之前的
    // allowedDates: val => moment(val).unix() >= moment(moment(new Date()).format("YYYY-MM-DD")).unix(),
    allowedDates(val) {
      let d = new Date();
      d.setSeconds(0);
      let unix;
      let days; //每个日期按钮距离当天的日子
      let startOfDayUnix; //当天0点
      let valUnix; //每个日期按钮的0点
      let limit =
        this.tab == "tab-2"
          ? this.advanceBookingLimit
          : this.advanceRoomBookingLimit;
      if (this.timezone) {
        valUnix = moment.tz(val, this.timezone).format("x");
        startOfDayUnix = moment.tz(d, this.timezone).startOf("day").format("x");
        unix = moment.tz(val, this.timezone).format("x");
        days = moment
          .tz(unix, "x", this.timezone)
          .diff(moment(startOfDayUnix, "x"), "days");
      } else {
        unix = moment(val).format("x");
        days = moment(unix, "x").diff(moment(Date.now() - 8.64e7, "x"), "days");
      }
      if (limit) {
        if (this.timezone) {
          return valUnix >= startOfDayUnix && days >= 0 && days <= limit;
        } else {
          return (
            Date.parse(val) > Date.now() - 8.64e7 && days >= 0 && days <= limit
          );
        }
      } else {
        if (this.timezone) {
          return valUnix >= startOfDayUnix;
        } else {
          return Date.parse(val) > Date.now() - 8.64e7;
        }
      }
    },
    // 获取用户在当前campus的默认building和floor
    // getUserLocation() {
    //   let _this = this;
    //   this.$axios({
    //     method: 'GET',
    //     url: 'm/campus/userDefaultLocation',
    //     params: {
    //       meetingId: localStorage.meetingIdMap,
    //       token: localStorage.token
    //     }
    //   }).then(res => {
    //     _this.buildingName = res.data.data.defaultBuilding;
    //     _this.floorName = res.data.data.defaultFloor;
    //     this.listNote = 'Location: ' + this.buildingName + ' on ' + this.floorName;
    //     // console.log("buildingName:", _this.buildingName);
    //     // console.log("floorName:", _this.floorName);
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // },
    // AVAILABILITY
    availabilityClick(index) {
      this.availabilityArr.forEach((i, k) => {
        if (index == k) {
          this.availabilityArr[k].active = "active";
          if (i.value == "Custom") {
            this.showDatePicker = true;
          } else {
            this.showDatePicker = false;
            this.dateText = "";
            this.dateEndText = "";
          }
        } else {
          this.availabilityArr[k].active = "";
        }
      });
    },
    // CAPACITY
    capacityClick(index) {
      this.capacity.forEach((i, k) => {
        if (index == k) {
          this.capacity[k].active = "active";
        } else {
          this.capacity[k].active = "";
        }
      });
    },
    // AMENITIES
    roomAmenitiesClick(index) {
      this.roomAmenities.forEach((i, k) => {
        if (index == k && this.roomAmenities[k].active == "active") {
          this.roomAmenities[k].active = "";
        } else if (index == k && this.roomAmenities[k].active != "active") {
          this.roomAmenities[k].active = "active";
        }
      });
    },
    // AMENITIES(DESK)
    deskAmenitiesClick(index) {
      this.deskAmenities.forEach((i, k) => {
        if (index == k && this.deskAmenities[k].active == "active") {
          this.deskAmenities[k].active = "";
        } else if (index == k && this.deskAmenities[k].active != "active") {
          this.deskAmenities[k].active = "active";
        }
      });
    },
    //AVALABLE FOR(DESK)
    // timeClick(index) {
    //   this.time.forEach((i, k) => {
    //     if (index == k) {
    //       this.time[k].active = 'active';
    //     } else {
    //       this.time[k].active = '';
    //     }
    //   })
    // },
    // 日期格式转换
    // parseDate(date) {
    //   console.log('date!!:', date)
    //   if (!date) return null;
    //   const [month, day, year] = date.split('/');
    //   console.log('dateStart:', `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`)
    //   return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    // },
    // parseEndDate(date) {
    //   console.log("dateEnd:",date)
    //   if (!date) return null;
    //   const [month, day, year] = date.split('/');
    //   return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    // },
    parseDate(date) {
      console.log("parseDate：:", date);
      if (!date) return null;
      let st = moment(date, "MMM D, YYYY").format("YYYY-MM-DD");
      return st;
    },
    parseEndDate(date) {
      console.log("parseDateEnd：", date);
      if (!date) return null;
      let st = moment(date, "MMM D, YYYY").format("YYYY-MM-DD");
      return st;
    },
    parseDateNew(date, type) {
      console.log("parseDateNew:");
      console.log("date:", date);
      console.log("type:", type);
      if (!date) return null;
      let st = moment(date, "MMM D, YYYY").format("YYYY-MM-DD");
      console.log("st:", st);
      if (type == "date") {
        this.date = st;
      } else if (type == "dateHourly") {
        this.dateHourly = st;
      } else if (type == "dateHourlyEnd") {
        this.dateHourlyEnd = st;
      } else if (type == "dateDay") {
        console.log("this.dateDay:", this.dateDay);
        console.log("st:", st);
        this.dateDay = st;
        console.log("this.dateDay:", this.dateDay);
      } else if (type == "dateDayEnd") {
        this.dateDayEnd = st;
      }
    },

    // formatDate(date) {
    //   if (!date) return null;
    //   this.start = moment(date).unix();
    //   // this.start = date;
    //   const [year, month, day] = date.split('-');
    //   return `${month}/${day}/${year}`;
    // },
    // formatEndDate(date) {
    //   if (!date) return null;
    //   this.end = moment(date).unix();
    //   // this.end = date;
    //   const [year, month, day] = date.split('-');
    //   return `${month}/${day}/${year}`;
    // },
    formatDate(date) {
      if (!date) return null;
      this.start = moment(date).unix();
      let st = moment(date, "YYYY-MM-DD").format("MMM D, YYYY");
      return st;
    },
    formatEndDate(date) {
      if (!date) return null;
      this.end = moment(date).unix();
      let st = moment(date, "YYYY-MM-DD").format("MMM D, YYYY");
      return st;
    },
    // 检验日期是否大于规定日期（默认30天，后天有返回就用后台的值，下同）
    dateInput() {
      this.menu1 = false;
      let beforeRuleDays = false;
      beforeRuleDays = this.beforeRuleDays(this.date);
      if (beforeRuleDays) {
        let msg = "Please select a date within the allowed timeframe.";
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.date = "";
            this.dateText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.date = "";
            this.dateText = "";
          });
        return;
      }
      let msg =
        "Can only be booked for up to " +
        this.advanceRoomBookingLimit +
        " days";
      let overRuleDate = false;
      overRuleDate = this.overRuleDays(this.date, "room");
      if (overRuleDate) {
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.date = "";
            this.dateText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.date = "";
            this.dateText = "";
          });
        return;
      }
      if (this.dateEndText) {
        let isExceed = this.getD(this.date, this.dateEnd);
        if (isExceed == "exceed") {
          this.$confirm({
            title: "Confirmation",
            message: "The start date cannot be greater than the end date",
            show: true,
          })
            .then(() => {
              //用户点击确认后执行
              this.date = "";
              this.dateText = "";
            })
            .catch(() => {
              // 取消或关闭
              this.date = "";
              this.dateText = "";
            });
        }
      }
    },
    dateEndInput() {
      this.menu2 = false;
      let beforeRuleDays = false;
      beforeRuleDays = this.beforeRuleDays(this.dateEnd);
      if (beforeRuleDays) {
        let msg = "Please select a date within the allowed timeframe.";
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateEnd = "";
            this.dateEndText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateEnd = "";
            this.dateEndText = "";
          });
        return;
      }
      let msg =
        "Can only be booked for up to " +
        this.advanceRoomBookingLimit +
        " days";
      let overRuleDate = false;
      overRuleDate = this.overRuleDays(this.dateEnd, "room");
      if (overRuleDate) {
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateEnd = "";
            this.dateEndText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateEnd = "";
            this.dateEndText = "";
          });
        return;
      }
      if (this.dateText) {
        let isExceed = this.getD(this.date, this.dateEnd);
        if (isExceed == "exceed") {
          this.$confirm({
            title: "Confirmation",
            message: "The end date cannot be less than the start date",
            show: true,
          })
            .then(() => {
              //用户点击确认后执行
              this.dateEnd = "";
              this.dateEndText = "";
            })
            .catch(() => {
              // 取消或关闭
              this.dateEnd = "";
              this.dateEndText = "";
            });
        }
      }
    },
    dateHourlyInput() {
      this.menuHourlyStartDate = false;
      let beforeRuleDays = false;
      beforeRuleDays = this.beforeRuleDays(this.dateHourly);
      console.log("beforeRuleDays:", beforeRuleDays);
      if (beforeRuleDays) {
        let msg = "Please select a date within the allowed timeframe.";
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateHourly = "";
            this.dateHourlyText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateHourly = "";
            this.dateHourlyText = "";
          });
        return;
      }
      let msg =
        "Can only be booked for up to " + this.advanceBookingLimit + " days";
      let overRuleDate = false;
      overRuleDate = this.overRuleDays(this.dateHourly);
      console.log("overRuleDate:", overRuleDate);
      if (overRuleDate) {
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateHourly = "";
            this.dateHourlyText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateHourly = "";
            this.dateHourlyText = "";
          });
        return;
      }
      if (this.dateHourlyEndText) {
        let isExceed = this.getD(this.dateHourly, this.dateHourlyEnd);
        if (isExceed == "exceed") {
          this.$confirm({
            title: "Confirmation",
            message: "The start date cannot be greater than the end date",
            show: true,
          })
            .then(() => {
              //用户点击确认后执行
              this.dateHourly = "";
              this.dateHourlyText = "";
            })
            .catch(() => {
              // 取消或关闭
              this.dateHourly = "";
              this.dateHourlyText = "";
            });
        }
      }
    },
    dateHourlyEndInput() {
      this.menuHourlyEndDate = false;
      let beforeRuleDays = false;
      beforeRuleDays = this.beforeRuleDays(this.dateHourlyEnd);
      if (beforeRuleDays) {
        let msg = "Please select a date within the allowed timeframe.";
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateHourlyEnd = "";
            this.dateHourlyEndText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateHourlyEnd = "";
            this.dateHourlyEndText = "";
          });
        return;
      }
      let msg =
        "Can only be booked for up to " + this.advanceBookingLimit + " days";
      let overRuleDate = false;
      overRuleDate = this.overRuleDays(this.dateHourlyEnd);
      if (overRuleDate) {
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateHourlyEnd = "";
            this.dateHourlyEndText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateHourlyEnd = "";
            this.dateHourlyEndText = "";
          });
        return;
      }
      if (this.dateHourlyText) {
        let isExceed = this.getD(this.dateHourly, this.dateHourlyEnd);
        if (isExceed == "exceed") {
          this.$confirm({
            title: "Confirmation",
            message: "The end date cannot be less than the start date",
            show: true,
          })
            .then(() => {
              //用户点击确认后执行
              this.dateHourlyEnd = "";
              this.dateHourlyEndText = "";
            })
            .catch(() => {
              // 取消或关闭
              this.dateHourlyEnd = "";
              this.dateHourlyEndText = "";
            });
        }
      }
    },
    dateDayInput() {
      console.log("2222222222222222222");
      console.log("dateDayInput");
      this.menuDayStartDate = false;
      let beforeRuleDays = false;
      beforeRuleDays = this.beforeRuleDays(this.dateDay);
      if (beforeRuleDays) {
        let msg = "Please select a date within the allowed timeframe.";
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateDay = "";
            this.dateDayText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateDay = "";
            this.dateDayText = "";
          });
        return;
      }
      let msg =
        "Can only be booked for up to " + this.advanceBookingLimit + " days";
      let overRuleDate = false;
      console.log("this.dateDay:", this.dateDay);
      overRuleDate = this.overRuleDays(this.dateDay);
      console.log("overRuleDate:", overRuleDate);
      if (overRuleDate) {
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateDay = "";
            this.dateDayText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateDay = "";
            this.dateDayText = "";
          });
        return;
      }
      if (this.dateDayEndText) {
        let isExceed = this.getD(this.dateDay, this.dateDayEnd);
        if (isExceed == "exceed") {
          this.$confirm({
            title: "Confirmation",
            message: "The start date cannot be greater than the end date",
            show: true,
          })
            .then(() => {
              //用户点击确认后执行
              this.dateDay = "";
              this.dateDayText = "";
            })
            .catch(() => {
              // 取消或关闭
              this.dateDay = "";
              this.dateDayText = "";
            });
        } else if (isExceed) {
          let msg =
            "Can only be booked for up to " +
            this.advanceBookingLimit +
            " days";
          this.$confirm({ title: "Confirmation", message: msg, show: true })
            .then(() => {
              //用户点击确认后执行
              this.dateDay = "";
              this.dateDayText = "";
            })
            .catch(() => {
              // 取消或关闭
              this.dateDay = "";
              this.dateDayText = "";
            });
        }
      }
    },
    dateDayEndInput() {
      console.log("run dateDayEndInput");
      this.menuDayEndDate = false;
      let beforeRuleDays = false;
      beforeRuleDays = this.beforeRuleDays(this.dateDayEnd);
      if (beforeRuleDays) {
        let msg = "Please select a date within the allowed timeframe.";
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateDayEnd = "";
            this.dateDayEndText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateDayEnd = "";
            this.dateDayEndText = "";
          });
        return;
      }
      let msg =
        "Can only be booked for up to " + this.advanceBookingLimit + " days";
      let overRuleDate = false;
      overRuleDate = this.overRuleDays(this.dateDayEnd);
      if (overRuleDate) {
        this.$confirm({ title: "Confirmation", message: msg, show: true })
          .then(() => {
            //用户点击确认后执行
            this.dateDayEnd = "";
            this.dateDayEndText = "";
          })
          .catch(() => {
            // 取消或关闭
            this.dateDayEnd = "";
            this.dateDayEndText = "";
          });
        return;
      }
      if (this.dateDayText) {
        let isExceed = this.getD(this.dateDay, this.dateDayEnd);
        if (isExceed == "exceed") {
          this.$confirm({
            title: "Confirmation",
            message: "The end date cannot be less than the start date",
            show: true,
          })
            .then(() => {
              //用户点击确认后执行
              this.dateDayEnd = "";
              this.dateDayEndText = "";
            })
            .catch(() => {
              // 取消或关闭
              this.dateDayEnd = "";
              this.dateDayEndText = "";
            });
        }
      }
    },
    searchList() {
      if (this.tab == "tab-1") {
        this.searchListTitle = "Room Availability";
        // if (window.gtag) {
        //   window.gtag("event", "Space_Find_A_Place", {
        //     event_category: "Type of space",
        //     event_label: "room",
        //     send_to: localStorage.getItem("googleAnaId") || "",
        //     dimension1: localStorage.getItem("campusName") || ""
        //   });
        // }
        this.$gtag.event('Space_Find_A_Place', {
          event_category: "Type of space",
          event_label: "room",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
      } else {
        this.searchListTitle = "Desk Availability";
        // if (window.gtag) {
        //   window.gtag("event", "Space_Find_A_Place", {
        //     event_category: "Type of space",
        //     event_label: "desk",
        //     send_to: localStorage.getItem("googleAnaId") || "",
        //     dimension1: localStorage.getItem("campusName") || ""
        //   });
        // }
        this.$gtag.event('Space_Find_A_Place', {
          event_category: "Type of space",
          event_label: "desk",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
      }
      if (this.tab == "tab-1") {
        console.log("Click Tab1");
        let obj = {};
        let start = "";
        let end = "";
        let shouldReturn = false; //时间格式有问题则return
        this.availabilityArr.map((item) => {
          if (item.active == "active") {
            console.log("itemitemitem", item);
            if (item.value == "Custom") {
              if (!this.date) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a start date.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              if (!this.dateEnd) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a end date.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              if (!this.dateStartTime) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a start time.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              if (!this.dateEndTime) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a end time.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              this.timeType = "custom";
              let stringStart = this.date + " " + this.dateStartTime; //2022-04-21 01:00 pm
              let stringEnd = this.dateEnd + " " + this.dateEndTime;
              // 1hr 2hr不同，这里选好的日期，直接转换成时间戳是本地时区的，所以要先把日期改为所选timezone的日期再转换成时间戳
              let timezone = localStorage.getItem("timezone");
              if (timezone) {
                start = moment
                  .tz(stringStart, "YYYY-MM-DD h:mm a", timezone)
                  .unix();
                end = moment
                  .tz(stringEnd, "YYYY-MM-DD h:mm a", timezone)
                  .unix();
              } else {
                start = moment(stringStart).unix();
                end = moment(stringEnd).unix();
              }
              console.log("startCustom", start);

              // start = moment(stringStart).unix();
              // end = moment(stringEnd).unix();
              // if (window.gtag) {
              //   window.gtag("event", "Space_Find_A_Place", {
              //     event_category: "Room availability",
              //     event_label: "custom",
              //     send_to: localStorage.getItem("googleAnaId") || "",
              //     dimension1: localStorage.getItem("campusName") || ""
              //   });
              // }
              this.$gtag.event('Space_Find_A_Place', {
                event_category: "Room availability",
                event_label: "custom",
                send_to: localStorage.getItem("googleAnaId") || "",
                dimension1: localStorage.getItem("campusName") || "",
              });
            } else {
              let event_label;
              let duration;
              if (item.value == "15m") {
                duration = 15;
                event_label = "15min";
              } else if (item.value == "30m") {
                duration = 30;
                event_label = "30min";
              } else if (item.value == "1hr") {
                duration = 60;
                event_label = "1hr";
              } else if (item.value == "2hr") {
                duration = 120;
                event_label = "2hr";
              } else if (item.value == "All day") {
                duration = 540;
                event_label = "All day";
              }
              this.timeType = "normal";
              let d = new Date();
              d.setSeconds(0);
              let min = d.getMinutes();
              let remainder = min % 30; //比30小还是大，取余获取到的都是正数
              console.log("remainder:", remainder);

              start = moment(d).add(-remainder, "minutes").unix();
              if (item.value == "15m" && remainder >= 15) {
                console.log("item:", item);
                start = moment(start, "X").add(15, "minutes").unix();
              }
              end = moment(start, "X")
                .add(+duration, "minutes")
                .unix();
              // if (window.gtag) {
              //   window.gtag("event", "Space_Find_A_Place", {
              //     event_category: "Room availability",
              //     event_label: event_label,
              //     send_to: localStorage.getItem("googleAnaId") || "",
              //     dimension1: localStorage.getItem("campusName") || ""
              //   });
              // }
              this.$gtag.event('Space_Find_A_Place', {
                event_category: "Room availability",
                event_label: event_label,
                send_to: localStorage.getItem("googleAnaId") || "",
                dimension1: localStorage.getItem("campusName") || "",
              });
            }
          }
        });
        if (shouldReturn) {
          return;
        }
        if (isNaN(start) || isNaN(end)) {
          this.$confirm({
            title: "Confirmation",
            message:
              "You must select hours, minutes and am/pm for booking start and end time ",
            show: true,
          });
          return;
        } else if (start > end) {
          this.$confirm({
            title: "Confirmation",
            message: "The end time must be greater than the start time",
            show: true,
          });
          return;
        }
        this.start = start;
        this.end = end;
        let arrCapacity = "";
        this.capacity.map((item) => {
          if (item.active == "active") {
            arrCapacity = item.value;
          }
        });
        let arrAmenities = [];
        console.log("start123", start);
        obj.start = start;
        obj.end = end;
        obj.capacity = arrCapacity;
        obj.amenities = arrAmenities;
        obj.isCustom = this.timeType == "custom" ? true : false;
        obj.type = "room";
        this.roomAmenities.map((item) => {
          if (item.active == "active") {
            arrAmenities.push(item.value);
          }
        });
        this.$emit("changeSearchList", obj);
        // this.$emit("changeShowList", true);
        // if (window.gtag) {
        //   window.gtag("event", "Space_Find_A_Place", {
        //     event_category: "Room capacity",
        //     event_label: arrCapacity,
        //     send_to: localStorage.getItem("googleAnaId") || "",
        //     dimension1: localStorage.getItem("campusName") || ""
        //   });
        // }
        this.$gtag.event('Space_Find_A_Place', {
          event_category: "Room capacity",
          event_label: arrCapacity,
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
        // if (window.gtag) {
        //   window.gtag("event", "Space_Find_A_Place", {
        //     event_category: "Room amenities",
        //     event_label: arrAmenities,
        //     send_to: localStorage.getItem("googleAnaId") || "",
        //     dimension1: localStorage.getItem("campusName") || ""
        //   });
        // }
        this.$gtag.event('Space_Find_A_Place', {
          event_category: "Room arrAmenities",
          event_label: arrAmenities,
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
      } else {
        console.log("Click Tab2");
        let d = new Date();
        d.setSeconds(0);
        let year;
        let month;
        let date;
        if (this.timezone) {
          year = String(moment.tz(d, this.timezone).format("YYYY"));
          month = String(moment.tz(d, this.timezone).format("M"));
          date = String(moment.tz(d, this.timezone).format("D"));
        } else {
          year = String(moment(d).format("YYYY"));
          month = String(moment(d).format("M"));
          date = String(moment(d).format("D"));
        }
        console.log("year:", year);
        console.log("month:", month);
        console.log("date:", date);
        let dateString = year + "-" + month + "-" + date;
        console.log("dateString:", dateString);
        let obj = {};
        obj.type = "desk";
        let start = "";
        let end = "";
        let shouldReturn = false; //判断时间区间是否正确,不正确直接不search List 了
        let timezone = localStorage.getItem("timezone");
        let isStartEndOfDate = false; //是否选择的是Desk的Start/End of Date
        let workingHourStartTime =
          localStorage.getItem("workingHourStartTime") || "00:00";
        let workingHourEndTime =
          localStorage.getItem("workingHourEndTime") || "23:59";
        let workingHourStartTimeAndYear =
          dateString + "-" + workingHourStartTime;
        let workingHourEndTimeAndYear = dateString + "-" + workingHourEndTime;
        this.availBtns.map((item) => {
          if (item.active == "active") {
            let startTimeLocalUnix;
            let endTimeLocalUnix;
            if (timezone) {
              startTimeLocalUnix = moment
                .tz(workingHourStartTimeAndYear, "YYYY-M-D-HH:mm", timezone)
                .unix();
              endTimeLocalUnix = moment
                .tz(workingHourEndTimeAndYear, "YYYY-M-D-HH:mm", timezone)
                .unix();
            } else {
              startTimeLocalUnix = moment(
                workingHourStartTimeAndYear,
                "YYYY-M-D-HH:mm"
              )
                .local()
                .unix();
              endTimeLocalUnix = moment(
                workingHourEndTimeAndYear,
                "YYYY-M-D-HH:mm"
              )
                .local()
                .unix();
            }
            // if (timezone) {
            //   startTimeLocalUnix = moment
            //     .tz(workingHourStartTime, "HH:mm", timezone)
            //     .unix();
            //   endTimeLocalUnix = moment
            //     .tz(workingHourEndTime, "HH:mm", timezone)
            //     .unix();
            // } else {
            //   startTimeLocalUnix = moment(workingHourStartTime, "HH:mm")
            //     .local()
            //     .unix();
            //   endTimeLocalUnix = moment(workingHourEndTime, "HH:mm")
            //     .local()
            //     .unix();
            // }

            // let startTimeLocalUnix = moment(workingHourStartTime, 'HH:mm').local().unix();
            // let endTimeLocalUnix = moment(workingHourEndTime, 'HH:mm').local().unix();
            console.log("startTimeLocalUnix:", startTimeLocalUnix);
            console.log("endTimeLocalUnix:", endTimeLocalUnix);
            let timeHasValue = false;
            // 获取当前的时间戳和当前的日期
            let d = new Date();
            d.setSeconds(0);
            let currentUnix = moment(d).unix();
            // let currentDate = moment(d).format("YYYY-MM-DD");
            let currentDate;
            if (this.timezone) {
              currentDate = moment.tz(d, this.timezone).format("YYYY-MM-DD");
            } else {
              currentDate = moment(d).format("YYYY-MM-DD");
            }
            let startOfDayUnix;
            if (timezone) {
              startOfDayUnix = moment.tz(d, timezone).startOf("day").unix();
            } else {
              startOfDayUnix = moment(d).startOf("day").unix();
            }
            console.log("startOfDayUnix`````````````````", startOfDayUnix);
            // if (workingHourStartTime == '' || workingHourStartTime == 'undefined' || workingHourEndTime == '' || workingHourEndTime == 'undefined') {
            //   timeHasValue = false;
            // }
            if (workingHourStartTime || workingHourEndTime) {
              timeHasValue = true;
            }
            if (item.value == "1") {
              this.timeType = "normal";
              let d = new Date();
              d.setSeconds(0);
              let currentUnix = moment(d).unix();
              d.setSeconds(0);
              let min = d.getMinutes();
              let remainder = min % 30;
              if (timeHasValue) {
                console.log("currentUnix123:", currentUnix);
                console.log("endTimeLocalUnix123:", endTimeLocalUnix);
                if (currentUnix > endTimeLocalUnix) {
                  // let msg = "The current time cannot be queried";
                  let msg = "Cannot book this desk at this time!";
                  this.$confirm({
                    title: "Confirmation",
                    message: msg,
                    show: true,
                  });

                  shouldReturn = true;
                  return;
                }
                console.log("==== 111111!!=====");
                // if (currentUnix >= startTimeLocalUnix) {
                //   this.start = moment(d).add(-remainder, "minutes").unix();
                //   this.end = endTimeLocalUnix;
                // } else {
                //   this.start = startTimeLocalUnix;
                //   this.end = endTimeLocalUnix;
                // }
                if (
                  currentUnix <= endTimeLocalUnix &&
                  currentUnix >= startTimeLocalUnix
                ) {
                  console.log("==== 情况1=====");
                  this.start = currentUnix;
                  this.end = endTimeLocalUnix;
                } else if (currentUnix < startTimeLocalUnix) {
                  // 2.
                  this.start = startTimeLocalUnix;
                  this.end = endTimeLocalUnix;
                } else {
                  // 3.
                  // let msg = "The current time cannot be queried";
                  let msg = "Cannot book this desk at this time!";
                  this.$confirm({
                    title: "Confirmation",
                    message: msg,
                    show: true,
                  });
                  shouldReturn = true;
                  return;
                }

                // this.start = moment(startTime, 'HH:mm').local().unix();
                // this.end = moment(endTime, 'HH:mm').local().unix();
              } else {
                console.log("==== 222222!!=====");
                let timezone = localStorage.getItem("timezone");
                this.start = moment(d).add(-remainder, "minutes").unix();
                if (timezone) {
                  // this.end = moment.tz(d,timezone).endOf('day').unix();
                  if (this.client == "Edwards Life Sciences") {
                    this.end = moment
                      .tz(d, timezone)
                      .add(9, "hours")
                      .add(-remainder, "minutes")
                      .set({ second: 0 })
                      .add(-1, "seconds")
                      .unix();
                  } else {
                    this.end = moment.tz(d, timezone).endOf("day").unix();
                  }
                } else {
                  if (this.client == "Edwards Life Sciences") {
                    this.end = moment(d)
                      .add(9, "hours")
                      .add(-remainder, "minutes")
                      .set({ second: 0 })
                      .add(-1, "seconds")
                      .unix();
                  } else {
                    this.end = moment(d).endOf("day").unix();
                  }
                }
                // this.end = moment(d).endOf('day').unix();
              }
              if (this.client == "WarnerMedia") {
                // if (window.gtag) {
                //   window.gtag("event", "Space_Find_A_Place", {
                //     event_category: "Desk available for",
                //     event_label: "Today",
                //     send_to: localStorage.getItem("googleAnaId") || "",
                //     dimension1: localStorage.getItem("campusName") || ""
                //   });
                // }
                this.$gtag.event('Space_Find_A_Place', {
                  event_category: "Desk available for",
                  event_label: "Today",
                  send_to: localStorage.getItem("googleAnaId") || "",
                  dimension1: localStorage.getItem("campusName") || "",
                });
                
              } else {
                // if (window.gtag) {
                //   window.gtag("event", "Space_Find_A_Place", {
                //     event_category: "Desk available for",
                //     event_label: "Today > end of Day",
                //     send_to: localStorage.getItem("googleAnaId") || "",
                //     dimension1: localStorage.getItem("campusName") || ""
                //   });
                // }
                this.$gtag.event('Space_Find_A_Place', {
                  event_category: "Desk available for",
                  event_label: "Today > end of Day",
                  send_to: localStorage.getItem("googleAnaId") || "",
                  dimension1: localStorage.getItem("campusName") || "",
                });
              }
            } else if (item.value == 2) {
              // console.log('dateHourly:', this.dateHourly);
              // console.log('hourlyStartTime:', this.hourlyStartTime);
              // console.log('dateHourlyEnd:', this.dateHourlyEnd);
              // console.log('hourlyEndTime:', this.hourlyEndTime);
              if (!this.dateHourly) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a start date.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              if (!this.dateHourlyEnd) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a end date.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              if (!this.hourlyStartTime) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a start time.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              if (!this.hourlyEndTime) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a end time.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              this.timeType = "custom";
              let stringStart = this.dateHourly + " " + this.hourlyStartTime;
              let stringEnd = this.dateHourlyEnd + " " + this.hourlyEndTime;
              // 和上面的1hr 2hr不同，这里选好的日期，直接转换成时间戳是本地时区的，所以要先把日期改为所选timezone的日期再转换成时间戳
              let timezone = localStorage.getItem("timezone");
              if (timezone) {
                // start = moment.tz(stringStart, "YYYY-MM-DD h:mm a", timezone).unix();
                // end = moment.tz(stringEnd, "YYYY-MM-DD h:mm a", timezone).unix();
                this.start = moment
                  .tz(stringStart, "YYYY-MM-DD h:mm a", timezone)
                  .unix();
                this.end = moment
                  .tz(stringEnd, "YYYY-MM-DD h:mm a", timezone)
                  .set({ second: 0 })
                  .add(-1, "seconds")
                  .unix();
                // this.end = moment.tz(stringEnd, "YYYY-MM-DD h:mm a", timezone).unix();
              } else {
                this.start = moment(stringStart).unix();
                this.end = moment(stringEnd)
                  .set({ second: 0 })
                  .add(-1, "seconds")
                  .unix();
              }
              // this.start = moment(stringStart).unix();
              // this.end = moment(stringEnd).unix();
              if (window.gtag) {
                window.gtag("event", "Space_Find_A_Place", {
                  event_category: "Desk available for",
                  event_label: "Hourly",
                  send_to: localStorage.getItem("googleAnaId") || "",
                  dimension1: localStorage.getItem("campusName") || "",
                });
              }
            } else if (item.value == 3) {
              isStartEndOfDate = true;
              console.log("====选择了Start Date End Date =====");
              this.timeType = "custom";
              if (!this.dateDay) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a start date.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              this.timeType = "custom";
              if (!this.dateDayEnd) {
                this.$confirm({
                  title: "Confirmation",
                  message: "Please select a end date.",
                  show: true,
                });
                shouldReturn = true;
                return;
              }
              if (timeHasValue) {
                console.log("====有时间范围限制=====");

                console.log("====777777777777777777777 =====");
                // console.log("==== 判断2=====",this.dateDayEnd!=currentDate)
                // console.log("==== 判断3=====",this.dateDay!=currentDate&&this.dateDayEnd!=currentDate)
                //一.开始日期和结束日期都是当天
                // 1.当前时间是在限制范围内
                // 2.当前时间是在限制开始时间前
                // 3.当前时间是在限制结束时间后
                if (
                  this.dateDay == this.dateDayEnd &&
                  this.dateDay == currentDate
                ) {
                  console.log("==== 开始日期和结束日期都是当天=====");
                  // 1.
                  if (
                    currentUnix <= endTimeLocalUnix &&
                    currentUnix >= startTimeLocalUnix
                  ) {
                    console.log("==== 情况1=====");
                    this.start = currentUnix;
                    this.end = endTimeLocalUnix;
                  } else if (currentUnix < startTimeLocalUnix) {
                    // 2.
                    this.start = startTimeLocalUnix;
                    this.end = endTimeLocalUnix;
                  } else {
                    // 3.
                    let msg = "Cannot book this desk at this time!";
                    this.$confirm({
                      title: "Confirmation",
                      message: msg,
                      show: true,
                    });

                    shouldReturn = true;
                    return;
                  }
                } else if (
                  this.dateDay == currentDate &&
                  this.dateDayEnd != currentDate
                ) {
                  console.log("==== 开始日期是当天,结束日期不是当天=====");
                  //二.开始日期是当天,结束日期不是当天
                  // 1.当前时间是在限制范围内
                  if (
                    currentUnix <= endTimeLocalUnix &&
                    currentUnix >= startTimeLocalUnix
                  ) {
                    this.start = currentUnix;
                    let endString = this.dateDayEnd + workingHourEndTime;
                    if (timezone) {
                      this.end = moment
                        .tz(endString, "YYYY-MM-DDHH:mm", timezone)
                        .unix();
                    } else {
                      this.end = moment(endString, "YYYY-MM-DDHH:mm").unix();
                    }
                    // this.end = moment(endString, "YYYY-MM-DDHH:mm").unix();
                  } else if (currentUnix < startTimeLocalUnix) {
                    // 2.当前时间是在限制开始时间前
                    this.start = startTimeLocalUnix;
                    let endString = this.dateDayEnd + workingHourEndTime;
                    if (timezone) {
                      this.end = moment
                        .tz(endString, "YYYY-MM-DDHH:mm", timezone)
                        .unix();
                    } else {
                      this.end = moment(endString, "YYYY-MM-DDHH:mm").unix();
                    }
                    // this.end = moment(endString, "YYYY-MM-DDHH:mm").unix();
                  } else {
                    // 3.当前时间是在限制结束时间后
                    let msg = "Cannot book this desk at this time!";
                    this.$confirm({
                      title: "Confirmation",
                      message: msg,
                      show: true,
                    });

                    shouldReturn = true;
                    return;
                  }
                } else if (
                  this.dateDay != currentDate &&
                  this.dateDayEnd != currentDate
                ) {
                  //三.开始日期和结束日期都不是当天
                  console.log("==== 开始日期和结束日期都不是当天=====");
                  let startString = this.dateDay + workingHourStartTime;
                  let endString = this.dateDayEnd + workingHourEndTime;
                  console.log("====startString!!!!!!!!! =====", startString);
                  console.log("====endString!!!!!!!!! =====", endString);
                  if (timezone) {
                    this.start = moment
                      .tz(startString, "YYYY-MM-DDHH:mm", timezone)
                      .unix();
                    this.end = moment
                      .tz(endString, "YYYY-MM-DDHH:mm", timezone)
                      .unix();
                  } else {
                    this.start = moment(startString, "YYYY-MM-DDHH:mm").unix();
                    this.end = moment(endString, "YYYY-MM-DDHH:mm").unix();
                  }
                }

                // 当前meeting有时间范围限制,开始时间和结束时间即头和尾限制范围
                // let startString = this.dateDay + workingHourStartTime;
                // let endString = this.dateDayEnd + workingHourEndTime;
                // console.log("====startString!!!!!!!!! =====",startString)
                // console.log("====endString!!!!!!!!! =====",endString)
                // this.start = moment(startString,"YYYY-MM-DDHH:mm").unix();
                // this.end = moment(endString,"YYYY-MM-DDHH:mm").unix();
              } else {
                // 当前meeting没有时间范围限制,从12:00am - 23:59pm
                console.log("没有时间范围限制");
                console.log("this.dateDay", this.dateDay);
                console.log("this.dateDayEnd", this.dateDayEnd);
                let timezone = localStorage.getItem("timezone");
                if (timezone) {
                  this.start = moment
                    .tz(this.dateDay, "YYYY-MM-DD", timezone)
                    .unix();
                  this.end = moment
                    .tz(this.dateDayEnd, "YYYY-MM-DD", timezone)
                    .endOf("day")
                    .unix();
                } else {
                  this.start = moment(this.dateDay).unix();
                  this.end = moment(this.dateDayEnd).endOf("day").unix();
                }
                // this.start = moment(this.dateDay).unix();
                // this.end = moment(this.dateDayEnd).endOf('day').unix();
              }
              if (window.gtag) {
                window.gtag("event", "Space_Find_A_Place", {
                  event_category: "Desk available for",
                  event_label: "Start/End of Day",
                  send_to: localStorage.getItem("googleAnaId") || "",
                  dimension1: localStorage.getItem("campusName") || "",
                });
              }
            }
          }
        });
        if (shouldReturn) {
          return;
        }
        let arrAmenities = [];
        this.deskAmenities.map((item) => {
          if (item.active == "active") {
            arrAmenities.push(item.value);
          }
        });
        console.log("start", this.start);
        console.log("end", this.end);
        if (isNaN(this.start) || isNaN(this.end)) {
          this.$confirm({
            title: "Confirmation",
            message:
              "You must select hours, minutes and am/pm for booking start and end time ",
            show: true,
          });
          return;
        } else if (this.start > this.end) {
          this.$confirm({
            title: "Confirmation",
            message: "The end time must be greater than the start time",
            show: true,
          });
          return;
        }
        obj.start = this.start;
        obj.end = this.end;
        obj.amenities = arrAmenities;
        this.$emit("changeSearchList", obj, isStartEndOfDate);
        // this.$emit("changeShowList", true);
        // if (window.gtag) {
        //   window.gtag("event", "Space_Find_A_Place", {
        //     event_category: "Desk amenities",
        //     event_label: arrAmenities,
        //     send_to: localStorage.getItem("googleAnaId") || "",
        //     dimension1: localStorage.getItem("campusName") || ""
        //   });
        // }
        this.$gtag.event('Space_Find_A_Place', {
          event_category: "Desk amenities",
          event_label: arrAmenities,
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
      }
      // if (window.gtag) {
      //   window.gtag("event", "Space", {
      //     event_category: "Space Search Keyworks",
      //     event_label: "search",
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || ""
      //   });
      // }
      this.$gtag.event('Space', {
        event_category: "Space Search Keyworks",
        event_label: "search",
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || "",
      });
    },
    getD(starDate, endDate) {
      console.log("starDate:", starDate);
      console.log("endDate:", endDate);
      let sDate = moment(new Date(starDate)).startOf("days").unix() * 1000;
      let eDate = moment(new Date(endDate)).endOf("days").unix() * 1000;
      // let sDate = new Date(starDate).getTime();
      // let eDate = new Date(endDate).getTime();
      // console.log('sDate:', sDate)
      // console.log('eDate:', eDate)
      // console.log("差值：",eDate - sDate)
      let thisMothDays = "";
      if (this.advanceBookingLimit) {
        thisMothDays = 1000 * 3600 * 24 * this.advanceBookingLimit; //后台有返回就用后台返回的值
      } else {
        thisMothDays = 1000 * 3600 * 24 * 30; //默认30天
      }
      console.log("thisMothDays:", thisMothDays);
      if (sDate > eDate) {
        // console.log('开始日期不得大于结束日期')
        return "exceed";
      }
    },
    test() {
      console.log("123");
    },
    beforeRuleDays(date) {
      let dateTransform;
      let today;
      if (this.timezone) {
        today =
          moment.tz(new Date(), this.timezone).startOf("days").unix() * 1000;
        dateTransform =
          moment.tz(date, this.timezone).startOf("days").unix() * 1000;
      } else {
        today = moment(new Date()).startOf("days").unix() * 1000;
        dateTransform = moment(date).startOf("days").unix() * 1000;
      }
      if (dateTransform < today) {
        console.log("dateTransform:", dateTransform);
        console.log("today:", today);
        return true;
      } else {
        return false;
      }
    },
    overRuleDays(date, type = "desk") {
      let dateTransform;
      if (
        type == "room" &&
        (localStorage.getItem("client") == "NatWest" ||
          localStorage.getItem("client") == "WarnerMedia")
      ) {
        return false;
      }
      // date = moment(date).endOf("days").unix() * 1000;
      // date = moment(date).startOf("days").unix() * 1000;
      let today;
      let todayisDST; //judge start date is summer time or winter time
      let endDayisDST; //judge start date is summer time or winter time
      if (this.timezone) {
        today =
          moment.tz(new Date(), this.timezone).startOf("days").unix() * 1000;
        todayisDST = moment
          .tz(new Date(), this.timezone)
          .startOf("days")
          .isDST();
        dateTransform =
          moment.tz(date, this.timezone).startOf("days").unix() * 1000;
        endDayisDST = moment.tz(date, this.timezone).startOf("days").isDST();
      } else {
        today = moment(new Date()).startOf("days").unix() * 1000;
        dateTransform = moment(date).startOf("days").unix() * 1000;
      }
      // let today = moment(new Date()).startOf('days').unix() * 1000;
      console.log("dateTransform:", dateTransform);
      console.log("today:", today);
      console.log("todayisDST:", todayisDST);
      console.log("endDayisDST:", endDayisDST);
      if (this.timezone) {
        if (todayisDST && !endDayisDST) {
          dateTransform -= 3600000;
        } else if (!todayisDST && endDayisDST) {
          dateTransform += 3600000;
        }
      }
      if (dateTransform < today) {
        console.log("dateTransform < today");
        return true;
      }
      let thisMothDays = "";
      if (type == "desk") {
        if (this.advanceBookingLimit) {
          thisMothDays = 1000 * 3600 * 24 * this.advanceBookingLimit; //后台有返回就用后台返回的值
          console.log("thisMothDays7:", thisMothDays);
        } else {
          thisMothDays = 1000 * 3600 * 24 * 31; //默认30天
          console.log("thisMothDays 31:", thisMothDays);
        }
      } else {
        console.log(
          "====advanceRoomBookingLimit =====",
          this.advanceRoomBookingLimit
        );
        if (this.advanceRoomBookingLimit) {
          thisMothDays = 1000 * 3600 * 24 * this.advanceRoomBookingLimit; //后台有返回就用后台返回的值
        } else {
          thisMothDays = 1000 * 3600 * 24 * 31; //默认30天
        }
      }
      console.log("====dateTransform =====", dateTransform);
      console.log("====dateTransform-today =====", dateTransform - today);
      console.log("====thisMothDays =====", thisMothDays);
      console.log("====chazhi =====", dateTransform - today - thisMothDays);
      if (dateTransform - today > thisMothDays) {
        // console.log('超过规定日期')
        return true;
      } else {
        // console.log('在规定日期内')
        return false;
      }
    },
    clearAll() {
      this.availabilityArr.map((item) => {
        item.active = "";
      });
      this.capacity.map((item) => {
        item.active = "";
      });
      this.roomAmenities.map((item) => {
        item.active = "";
      });
      this.deskAmenities.map((item) => {
        item.active = "";
      });
      this.date = "";
      this.dateEnd = "";
      this.dateText = "";
      this.dateEndText = "";
      this.dateStartTime = "12:00 am";
      this.dateEndTime = "12:00 am";

      this.dateHourly = "";
      this.dateHourlyEnd = "";
      this.dateHourlyText = "";
      this.dateHourlyEndText = "";
      this.hourlyStartTime = "12:00 am";
      this.hourlyEndTime = "12:00 am";

      console.log("==== hourlyStartTime=====", this.hourlyStartTime);

      this.dateDay = "";
      this.dateDayEnd = "";
      this.dateDayText = "";
      this.dateDayEndText = "";
    },
    clickAvailContent(item) {
      this.availBtns.map((btn) => {
        btn.active = "";
      });
      item.active = "active";
      switch (item.value) {
        case 1:
          this.showContent1 = true;
          this.showContent2 = false;
          this.showContent3 = false;
          break;
        case 2:
          this.showContent1 = false;
          this.showContent2 = true;
          this.showContent3 = false;
          break;
        case 3:
          this.showContent1 = false;
          this.showContent2 = false;
          this.showContent3 = true;
          break;
      }
    },
    // 选择default还是building-floor
    clickSearchBtn(item) {
      this.showFloors = false;
      let that = this;
      if (item.type == "default") {
        console.log("1111111111defaultBfData:", this.defaultBfData);
        this.bus.$emit("loading", true);
        this.listNote =
          "Your default location is " +
          this.defaultBfData.buildingName +
          " on " +
          this.defaultBfData.floorName;
        // this.listNote = 'Your default location is ' + this.buildingName + ' on ' + this.floorName;
        this.buildings = [];
        this.floors = [];
        let emitObj = {};
        emitObj.buildingName = this.defaultBfData.buildingName;
        emitObj.floor = this.defaultBfData.currentFloor;
        // emitObj.buildingName = this.buildingName;
        // emitObj.floor = this.currentFloor;
        this.$emit("refreshFilter", emitObj);
      } else if (item.type == "buildings") {
        // this.floors = [];
        this.listNote = "Please select a building";
        this.buildings = this.buildingsAndFloorsData;
        this.buildings.map((item) => {
          item.active = false;
        });
        // this.buildings = [
        //   {label: 'building1', type: 'building', id: 1, active: false},
        //   {label: 'building2', type: 'building', id: 2, active: false},
        // ];
      } else if (item.type == "building") {
        this.listNote = "Please select a floor";
      }
      console.log("info", this.listNote);
      this.searchBtns.map((btn) => {
        btn.active = false;
      });
      item.active = true;
    },
    // 选择building
    clickBuilding(item) {
      console.log("build:", item);
      this.currentBuilding = item;
      this.showFloors = true;
      this.listNote = "Please select a floor";
      let arr = [];
      let length = this.buildings.length;
      for (let i = 0; i < length; i++) {
        let build = this.buildings[i];
        if (build.id == item.id) {
          build.active = true;
          arr.push(build);
          break;
        } else {
          continue;
        }
      }
      this.buildings = arr;
      this.floors = item.children;
      this.floors.map((floor) => {
        floor.active = false;
      });
      // this.floors = [];
      // this.buildings.map((btn, index) => {
      //   console.log("btn:",btn)
      //   btn.active = false;
      //   if (item.id != btn.id) {
      //     console.log("true")
      //     // this.buildings.splice(index, 1);
      //   }else{
      //     console.log("false")
      //   }
      // })
      // item.active = true;
    },
    // 选择floor
    clickFloor(item) {
      this.bus.$emit("loading", true);
      this.listNote = "";
      let arr = [];
      let length = this.floors.length;
      for (let i = 0; i < length; i++) {
        let floor = this.floors[i];
        if (floor.id == item.id) {
          floor.active = true;
          arr.push(floor);
          break;
        } else {
          continue;
        }
      }
      this.floors = arr;
      let emitObj = {};
      emitObj.buildingName = this.currentBuilding.label;
      emitObj.floor = this.floors[0];
      this.$emit("refreshFilter", emitObj);
    },
    backToSearchList(refreshDefaulTime = false) {
      this.searchBtns[0].active = true;
      this.searchBtns[1].active = false;
      this.buildings = [];
      this.floors = [];
      let obj = {};
      obj.showList = false;
      obj.start = refreshDefaulTime ? "" : this.startTime;
      obj.end = refreshDefaulTime ? "" : this.endTime;
      this.$emit("backToSearchList", obj);
    },
    clickCanlender(id) {
      console.log("==== this.timeType=====", this.timeType);
      let timezone = localStorage.getItem("timezone");
      let d = new Date();
      let currentDateString;
      let startTimeString;
      let current = moment(d).unix();
      let obj = {};
      obj.id = id;
      if (this.timeType == "normal") {
        if (
          localStorage.workingHourStartTime &&
          localStorage.workingHourStartTime != "undefined"
        ) {
          console.log("11111!!");
          let startTimeLocalUnix;
          let startTime = localStorage.getItem("workingHourStartTime");
          console.log("startTime：", startTime);
          if (timezone) {
            currentDateString = moment.tz(d, timezone).format("YYYY-MM-DD");
            console.log("currentDateString:", currentDateString);
            console.log("startTime:", startTime);
            startTimeString = currentDateString + startTime;
            console.log("startTimeString:", startTimeString);
            startTimeLocalUnix = moment
              .tz(startTimeString, "YYYY-MM-DDHH:mm", timezone)
              .unix();
            // startTimeLocalUnix = moment.tz(startTimeString, "YYYY-HH-MM HH:mm", timezone).unix();
          } else {
            currentDateString = moment(d).format("YYYY-MM-DD");
            startTimeString = currentDateString + startTime;
            startTimeLocalUnix = moment(startTimeString, "YYYY-MM-DDHH:mm")
              .local()
              .unix();
            // startTimeLocalUnix = moment(startTimeString, "YYYY-HH-MM HH:mm").local().unix();
          }
          // if (timezone) {
          //   console.log('走1',);
          //   startTimeLocalUnix = moment.tz(startTime, "HH:mm", timezone).unix();
          // } else {
          //   console.log('走2',);
          //   startTimeLocalUnix = moment(startTime, "HH:mm").local().unix();
          // }
          console.log("startTimeLocalUnix:", startTimeLocalUnix);
          console.log("current:", current);
          if (current >= startTimeLocalUnix) {
            obj.start = current;
          } else {
            obj.start = startTimeLocalUnix;
          }
          // obj.start = startTimeLocalUnix;
        } else {
          console.log("22222!!");
          obj.start = current; //新改动，选择非自定义时间时，显示当前的具体时间而不是取整（半） 例如：3：35 就显示3：35
        }
      } else {
        obj.start = this.start; //旧版取整，选择自定义时间时，取整（半）显示，例：3：35显示3：30
      }
      // obj.start = this.start;//点击图片用回旧版取整，选择自定义时间时，取整（半）显示，例：3：35显示3：30  2022/5/25
      obj.end = this.end;
      obj.timeType = this.timeType;
      console.log("this.start::::", this.start);
      console.log("objobjobjobj::::", obj);
      this.$emit("clickCanlender", obj);
    },
    acceptMap(data) {
      this.$emit("acceptMap", data);
    },
    formatAmenities(data) {
      let arr = [];
      data.map((item, index) => {
        let obj = {};
        obj.active = "";
        obj.value = item;
        arr.push(obj);
      });
      return arr;
    },
    getAmenitiesData() {
      this.$axios({
        method: "GET",
        url: "/m/campus/amenities",
        data: {
          meetingId: localStorage.meetingIdMap,
          // token: localStorage.token
        },
      })
        .then((res) => {
          console.log("res:", res);
          this.deskAmenities = this.formatAmenities(
            res.data.data.deskAmenities
          );
          this.roomAmenities = this.formatAmenities(
            res.data.data.roomAmenities
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    open15Option() {
      // Edwards 的查询时间要变倒序并且加个All day（实际是9hrs）
      if (this.client == "Edwards Life Sciences") {
        this.availabilityArr = [
          {
            value: "All day",
            active: "active",
          },
          {
            value: "2hr",
            active: "",
          },
          {
            value: "1hr",
            active: "",
          },
          {
            value: "30m",
            active: "",
          },
          {
            value: "15m",
            active: "",
          },
          {
            value: "Custom",
            active: "",
          },
        ];
      } else {
        this.availabilityArr = [
          {
            value: "15m",
            active: "",
          },
          {
            value: "30m",
            active: "",
          },
          {
            value: "1hr",
            active: "",
          },
          {
            value: "2hr",
            active: "",
          },
          {
            value: "Custom",
            active: "",
          },
        ];
      }
    },
  },
  created() {
    console.log("bfData:", this.bfData);
    this.timezone = localStorage.getItem("timezone");
    this.client = localStorage.getItem("client");
    this.open15Option;
    this.showRoomButton = JSON.parse(localStorage.getItem("showRoomButton"));
    this.showDeskButton = JSON.parse(localStorage.getItem("showDeskButton"));
    if (!this.showRoomButton) {
      this.tab = "tab-2";
      this.tab2 = "tab-1";
    }
    if (this.client == "WarnerMedia" || this.client == "Pepsico") {
      this.availBtns = [
        { label: "Today", value: 1, active: "active" },
        { label: "Start/End of Date", value: 3, active: "false" },
      ];
    } else {
      this.availBtns = [
        { label: "Today > End of Day", value: 1, active: "active" },
        { label: "Hourly", value: 2, active: "" },
        { label: "Start/End of Date", value: 3, active: "false" },
      ];
    }
    let advanceBookingLimit = localStorage.getItem("advanceBookingLimit");
    let advanceRoomBookingLimit = localStorage.getItem(
      "advanceRoomBookingLimit"
    );
    let config = JSON.parse(localStorage.getItem("config"));
    this.advanceBookingLimit = advanceBookingLimit
      ? Number(advanceBookingLimit)
      : config.advanceBookingLimit;
    console.log("22222222222222", this.advanceBookingLimit);
    this.advanceRoomBookingLimit = advanceRoomBookingLimit
      ? Number(advanceRoomBookingLimit)
      : config.advanceRoomBookingLimit;
  },
  mounted() {
    this.getAmenitiesData();
  },
};
</script>

<style lang="scss" scoped>
#desks {
  height: 100%;
  @media screen and (max-width: 960px) {
    .titleContainer {
      margin: 230px auto !important;
    }
  }

  .availBtns {
    .active {
      //background: red
    }
  }

  .titleContainer {
    margin: 0;
  }

  .title {
    //background: $main-blue;
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .desks-content {
    //height: 81vh;
    height: 95%;
    overflow-y: auto;
    border: 1px solid #efefef;

    .v-window {
      overflow: auto !important;
    }

    .noResults {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      margin-top: 14rem;
      color: #999999;
    }

    .searchBtn {
      //background-color: $btn-color;
    }
  }

  .spaceActive {
    background: #666666;
    //background: #666666;
    color: #ffffff;
  }

  .annotation {
    margin: 20px;
    font-size: 17px;
  }

  .datePicker {
    margin: 5px;
  }

  //.v-tabs .v-tabs-bar .v-tab.v-tab--active {
  //  button {
  //    //background: $btn-color-highlight !important;
  //    background: #666666 !important;
  //    color: #fff !important;
  //  }
  //}

  .availTab {
    display: flex;
    flex-wrap: wrap !important;
    white-space: pre !important;
  }

  .iconTitle {
    font-size: 16px;
    color: #2d7d74;
    margin-right: 5px;
  }
}
</style>
<style lang="scss">
#desks {
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: unset !important;
  }
}

.v-menu__content {
  min-width: unset !important;
}
</style>
